import {
  SET_PENDING,
  SET_TOUCHED,
  SET_REPAIR_PARTS,
  FORGET_REPAIR_PARTS,
} from "./types";
import { Auth, Firestore } from "config";
const snapOptions = { includeMetadataChanges: true };
const findDocIndex = (array, id) =>
  array.findIndex((item) => item.DocId === id);

const repairPartList = [];
const repairPartRef = {};
let repairPartUnsubscribe = null;
export const acGetRepairParts = (dispatch, getState) => {
  // Used to prevent additional executions; This AC should only run when touched property is false;
  dispatch({ type: SET_TOUCHED, payload: true });

  // Use Auth listener to prevent unauthorized access from non-users; Also clears data when user logs off;
  Auth.onAuthStateChanged((user) => {
    if (user) {
      const collectionRef = Firestore.collection("repair-parts");
      const handleSnap = (snap) => {
        const docChangeSize = snap.docChanges().length;
        const isPending = getState().repairParts.pending;

        // If collection is empty and redux state is pending, then set pending to false to show first snap performed
        if (docChangeSize === 0 && isPending)
          dispatch({ type: SET_PENDING, payload: false });
        // If 1 or more doc change in snap, then loop through changes and dispatch to redux state
        else if (docChangeSize >= 1) {
          for (let i = 0; i < docChangeSize; i++) {
            const { type, doc } = snap.docChanges()[i];
            const docData = {
              ...doc.data(),
              DocId: doc.id,
              RepairPartId: doc.id,
            };
            if (type === "added") repairPartList.push(docData);
            if (type === "modified")
              repairPartList.splice(
                findDocIndex(repairPartList, doc.id),
                1,
                docData
              );
            if (type === "removed")
              repairPartList.splice(findDocIndex(repairPartList, doc.id), 1);
            repairPartRef[doc.id] = docData;
          }
          const repairParts = {
            all: [...repairPartList],
            active: repairPartList.filter((item) => item.Active),
            inactive: repairPartList.filter((item) => !item.Active),
            ref: repairPartRef,
          };
          dispatch({ type: SET_REPAIR_PARTS, payload: repairParts });
          dispatch({ type: SET_PENDING, payload: false });
        }
      };
      const handleError = (error) =>
        console.log("acGetRepairParts Error: ", error.message, { error });
      repairPartUnsubscribe = collectionRef.onSnapshot(
        snapOptions,
        handleSnap,
        handleError
      );
    } else {
      dispatch({ type: FORGET_REPAIR_PARTS });
      if (repairPartUnsubscribe) repairPartUnsubscribe();
    }
  });
};
