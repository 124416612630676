import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Drawer as MuiDrawer,
  Divider,
  List,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import xEVLogo from "media/xEVLogoTag.svg";
import { acSetDrawerOpen } from "state/ui/actions.js";
import { menuItems } from "routes";
import { ImportItem, LogoutItem, MenuItem } from "./Drawer/index";

const Drawer = () => {
  const drawerOpen = useSelector((state) => state.ui.drawerOpen);
  const smallDevice = useSelector((state) => state.ui.smallDevice);
  const { currentUser } = useSelector((state) => state.auth);
  const classes = useStyles();
  const dispatch = useDispatch();
  const muiDrawerProps = useMemo(
    () => ({
      classes: { paper: classes.drawerPaper },
      onClose: () => {
        if (smallDevice) dispatch(acSetDrawerOpen(false));
      },
      open: drawerOpen,
      PaperProps: {
        style: smallDevice ? { paddingTop: "0px" } : { paddingTop: "56px" },
      },
      variant: smallDevice ? "temporary" : "persistent",
    }),
    [classes, dispatch, drawerOpen, smallDevice]
  );

  return (
    <MuiDrawer {...muiDrawerProps}>
      <img src={xEVLogo} alt="xEV Strategies Logo" className={classes.logo} />
      <Typography className={classes.title}>HMA Battery Management</Typography>
      <Typography className={classes.email}>
        {currentUser?.email || "Pending"}
      </Typography>
      <Divider className={classes.divider} />
      <List className={classes.list}>
        {menuItems(currentUser).map((item) => (
          <MenuItem
            key={item.text}
            icon={item.icon()}
            link={item.link}
            text={item.text}
            classes={classes}
          />
        ))}
        <LogoutItem classes={classes} />
        {currentUser?.group === "Admin" &&
          process?.env?.REACT_APP_ENV === "development" && (
            <ImportItem classes={classes} />
          )}
      </List>
    </MuiDrawer>
  );
};

const useStyles = makeStyles((theme) => {
  const leftOffset = "32px";
  return {
    drawerPaper: {
      [theme.breakpoints.down("xs")]: {
        width: "75vw",
      },
      [theme.breakpoints.up("sm")]: {
        position: "fixed",
        whiteSpace: "nowrap",
        width: "300px",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      minHeight: "100vh",
      background: `linear-gradient(180deg, rgba(255, 255, 255, 0.98) 0%, rgba(255, 255, 255, 0.7) 100%), url('https://source.unsplash.com/XP-b7U3dVX8/1200x675')`,
      backgroundSize: "cover",
      boxShadow: "1px 0px 8px 2px rgba(0,0,0,0.25);",
      "@media print": {
        display: "none",
        width: 0,
        height: 0,
        overflow: "hidden",
        lineHeight: 0,
        whiteSpace: "nowrap",
      },
    },
    logo: {
      width: "175px",
      height: "100px",
      margin: leftOffset,
    },
    title: {
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "23px",
      paddingLeft: leftOffset,
      paddingBottom: "2px",
    },
    email: {
      fontSize: "14px",
      fontHeight: "normal",
      lineHeight: "20px",
      paddingLeft: leftOffset,
      paddingBottom: "13px",
    },
    divider: {
      height: "1px",
    },
    list: {
      padding: "16px 8px",
    },
    listItem: {
      margin: "4px 0px",
      "&.Mui-selected": {
        background: `rgba(237, 28, 36, 0.25)`,
        borderRadius: "4px",
        "&:hover": {
          background: `rgba(237, 28, 36, 0.25)`,
          borderRadius: "4px",
        },
        "& $listItemIcon": {
          color: "#ED1C24",
        },
      },
      "&:hover": {
        background: `rgba(237, 28, 36, 0.25)`,
        borderRadius: "4px",
      },
    },
    listItemTextPrimary: {
      color: "#464646",
      fontSize: "16px",
    },
    listItemIcon: {
      color: "#000000",
    },
  };
});
export default Drawer;
