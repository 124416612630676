import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { acGetPortalUsers } from "state/portal-users/actions";

const usePortalUsers = () => {
  const dispatch = useDispatch();
  const { all, active, inactive, ref, pending, touched } = useSelector(
    (state) => state.portalUsers
  );

  useEffect(() => {
    if (!touched) dispatch(acGetPortalUsers);
  }, [dispatch, touched]);

  return { pending, touched, all, active, inactive, ref };
};

export default usePortalUsers;
