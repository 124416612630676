import {
  SET_AFFILIATES,
  SET_BATTERY_PICKUPS,
  SET_BATTERY_TYPES,
  SET_DEALERS,
  SET_REPAIRS,
  SET_BATTERY_TYPE_LISTENER,
} from "../types";
import {
  SET_RECEIVING_SITES,
  SET_RECEIVING_SITES_LISTENER,
  RESET_FIRESTORE_STORE,
  SET_REPAIR_PARTS,
  SET_REPAIR_INVENTORY,
  SET_REPAIR_INVENTORY_SHIPMENTS,
} from "../types";
import {
  SET_QUEUED_BATTERY_ORDERS,
  SET_QUEUED_BATTERY_ORDERS_LISTENER,
} from "../types.js";

const INITIAL_STATE = {
  affiliates: null,
  batteryPickups: null,
  batteryTypes: null,
  batteryTypesListener: null,
  dealers: null,
  repairs: null,
  receivingSites: null,
  receivingSiteListener: null,
  repairParts: null,
  repairInventory: null,
  repairInventoryShipments: null,
  queuedBatteryOrders: null,
  queuedBatteryOrdersListener: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_AFFILIATES:
      return { ...state, affiliates: payload };
    case SET_BATTERY_PICKUPS:
      return { ...state, batteryPickups: payload };
    case SET_BATTERY_TYPES:
      return { ...state, batteryTypes: payload };
    case SET_BATTERY_TYPE_LISTENER:
      return { ...state, batteryTypesListener: payload };
    case SET_DEALERS:
      return { ...state, dealers: payload };
    case SET_RECEIVING_SITES:
      return { ...state, receivingSites: payload };
    case SET_RECEIVING_SITES_LISTENER:
      return { ...state, receivingSiteListener: payload };
    case SET_REPAIR_PARTS:
      return { ...state, repairParts: payload };
    case SET_REPAIR_INVENTORY:
      return { ...state, repairInventory: payload };
    case SET_REPAIR_INVENTORY_SHIPMENTS:
      return { ...state, repairInventoryShipments: payload };
    case SET_REPAIRS:
      return { ...state, repairs: payload };
    case SET_QUEUED_BATTERY_ORDERS:
      return { ...state, queuedBatteryOrders: payload };
    case SET_QUEUED_BATTERY_ORDERS_LISTENER:
      return { ...state, queuedBatteryOrdersListener: payload };
    case RESET_FIRESTORE_STORE:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
