import React from "react";
import { LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Drawer, MainView } from "components/AppFrame/";
import { ErrorBoundary, Snackbar } from "components";
import { useSmallDeviceCheck } from "hooks";

const App = () => {
  const classes = useStyles();
  const { smallDevice } = useSmallDeviceCheck();

  if (smallDevice === null) return <LinearProgress color="secondary" />; // Do not render until App has determined whether device is small or standard size
  return (
    <div className={classes.root}>
      <AppBar />
      <Drawer />
      <Snackbar />
      <ErrorBoundary filePath="src/App.js">
        <MainView />
      </ErrorBoundary>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    minHeight: "100vh",
    overflow: "hidden",
    position: "relative",
    zIndex: 1,
  },
}));
export default App;

/*
class App extends Component {
  async componentDidCatch(error, info) {
    const {claims} = await Auth.currentUser.getIdTokenResult();
    if (process.env.REACT_APP_ENV === 'production') {
      Functions.httpsCallable('emailCatchError')({
        errorMessage: error.message,
        errorName: error.name,
        errorStack: info.componentStack,
        user: claims || 'No User Data found.',
      });
      window.location.replace('http://hma.xevstrategies.com/portal/error');
    }
  }
  render() {
    return (
      <AppFrame routes={routes}
        menuItems={createMenuItems(this.props.currentUser)}
        moduleTitle='HMA Battery Management'
        redirectTo={this.props.currentUser.group === 'HMA Techline' ? '/portal/battery-inventory' : '/portal/battery-pickup'}
        currentUserEmail={this.props.currentUser.email}
        xEVDrawerLogo={true}
      />
    );
  }
}

const mapStateToProps = state => {
  return {currentUser: state.auth.currentUser};
};

App.propTypes = {
  currentUser: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(App);
*/
