import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ErrorBoundary } from "components";
import useBackgroundImage from "./hooks/useBackgroundImage";
import SignInUserForm from "./SignInUserForm";

const SignIn = () => {
  const styles = useStyles();
  const { view } = useParams();
  const backgroundImage = useBackgroundImage();
  const backgroundStyle = useMemo(
    () => ({
      backgroundSize: "cover",
      backgroundImage: `url(${backgroundImage})`,
      opacity: 0.7,
      height: "100vh",
      width: "100vw",
    }),
    [backgroundImage]
  );

  if (backgroundImage === null) return <LinearProgress color="secondary" />;
  return (
    <ErrorBoundary filePath="src/views/SignIn/index.js">
      <div style={backgroundStyle} />
      <div className={styles.overlay} />
      <SignInUserForm view={view} />
    </ErrorBoundary>
  );
};

const useStyles = makeStyles((theme) => ({
  overlay: {
    backgroundColor: "#ED1C24",
    opacity: 0.3,
    height: "100vh",
    width: "100vw",
    position: "absolute",
    top: 0,
  },
}));
export default React.memo(SignIn);
