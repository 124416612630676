import {
  SET_PENDING,
  SET_TOUCHED,
  SET_PORTAL_USERS,
  FORGET_PORTAL_USERS,
} from "./types";
import { Auth, Functions } from "config";
import filterBy from "lodash.filter";

export const acGetPortalUsers = (dispatch) => {
  // Used to prevent additional executions; This AC should only run when touched property is false;
  dispatch({ type: SET_TOUCHED, payload: true });

  // Use Auth listener to prevent unauthorized access from non-users; Also clears data when user logs off;
  Auth.onAuthStateChanged(async (user) => {
    if (user) {
      try {
        const result = await Functions.httpsCallable("getUsers")();
        const nonDealershipUsers = filterBy(result.data, (user) => {
          return ["HMA", "HMA Techline", "Admin", "Affiliate", "KBI"].includes(
            user.group
          );
        });
        dispatch({ type: SET_PENDING, payload: false });
        dispatch({ type: SET_PORTAL_USERS, payload: [...nonDealershipUsers] });
      } catch (error) {
        dispatch({ type: SET_PENDING, payload: false });
        alert(error.message);
        console.log("acGetPortalUsers Error", error);
      }
    } else {
      dispatch({ type: FORGET_PORTAL_USERS });
    }
  });
};
export const acAddPortalUser =
  ({ portalUser, portalUsers }) =>
  async (dispatch) => {
    const setNonAffiliateUser = async () => {
      try {
        const response = await Functions.httpsCallable("createUser")({
          displayName: portalUser.DisplayName,
          email: portalUser.Email,
          group: portalUser.Group,
          password: portalUser.Password,
        });
        const newUser = {
          active: true,
          displayName: portalUser.DisplayName,
          email: portalUser.Email,
          group: portalUser.Group,
          uid: response.data.uid,
        };
        dispatch({
          type: SET_PORTAL_USERS,
          payload: [...portalUsers, newUser],
        });
        return { success: true, error: null };
      } catch (error) {
        alert(error.message);
        console.log({ error });
        return { success: false, error: error.message };
      }
    };
    const setAffiliateUser = async () => {
      try {
        const response = await Functions.httpsCallable("createAffiliateUser")({
          affiliateCode: portalUser.AffiliateCode,
          affiliateId: portalUser.AffiliateSiteId,
          displayName: portalUser.DisplayName,
          email: portalUser.Email,
          group: portalUser.Group,
          password: portalUser.Password,
        });
        const newUser = {
          active: true,
          affiliateCode: portalUser.AffiliateCode,
          affiliateId: portalUser.AffiliateSiteId,
          displayName: portalUser.DisplayName,
          email: portalUser.Email,
          group: portalUser.Group,
          uid: response.data.uid,
        };
        dispatch({
          type: SET_PORTAL_USERS,
          payload: [...portalUsers, newUser],
        });
        return { success: true, error: null };
      } catch (error) {
        alert(error.message);
        console.log(error);
        return { success: false, error: error.message };
      }
    };
    if (portalUser.Group !== "Affiliate") return await setNonAffiliateUser();
    else if (portalUser.Group === "Affiliate") return await setAffiliateUser();
  };
export const acUpdatePortalUser =
  ({ userId, portalUser, portalUsers }) =>
  async (dispatch) => {
    // Function to find index where updated user record is location in portalUsers array
    const getUserIndex = () =>
      portalUsers.findIndex((user) => user.uid === userId);

    const setNonAffiliateUser = async () => {
      try {
        const response = await Functions.httpsCallable("updateUser")({
          active: portalUser.Active,
          displayName: portalUser.DisplayName,
          email: portalUser.Email,
          group: portalUser.Group,
          uid: userId,
        });
        portalUsers.splice(getUserIndex(), 1, {
          active: portalUser.Active,
          displayName: portalUser.DisplayName,
          email: portalUser.Email,
          group: portalUser.Group,
          uid: response.data.uid,
        });
        dispatch({ type: SET_PORTAL_USERS, payload: [...portalUsers] });
        return { success: true, error: null };
      } catch (error) {
        alert(error.message);
        console.log(error);
        return { success: false, error: error.message };
      }
    };
    const setAffiliateUser = async () => {
      try {
        const response = await Functions.httpsCallable("updateAffiliateUser")({
          active: portalUser.Active,
          affiliateCode: portalUser.AffiliateCode,
          affiliateId: portalUser.AffiliateSiteId,
          displayName: portalUser.DisplayName,
          email: portalUser.Email,
          group: portalUser.Group,
          uid: userId,
        });
        portalUsers.splice(getUserIndex(), 1, {
          active: portalUser.Active,
          affiliateCode: portalUser.AffiliateCode,
          affiliateId: portalUser.AffiliateSiteId,
          displayName: portalUser.DisplayName,
          email: portalUser.Email,
          group: portalUser.Group,
          uid: response.data.uid,
        });
        dispatch({ type: SET_PORTAL_USERS, payload: [...portalUsers] });
        return { success: true, error: null };
      } catch (error) {
        alert(error.message);
        console.log(error);
        return { success: false, error: error.message };
      }
    };
    if (portalUser.Group !== "Affiliate") return await setNonAffiliateUser();
    else if (portalUser.Group === "Affiliate") return await setAffiliateUser();
  };
