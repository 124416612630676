// Auth Reducer Types
export const SET_CURRENT_USER = "auth/SET_CURRENT_USER";
export const RESET_AUTH_STORE = "auth/RESET_AUTH_STORE";

// Firestore Reducer Types
export const SET_AFFILIATES = "firestore/SET_AFFILIATES";
export const SET_BATTERY_PICKUPS = "firestore/SET_BATTERY_PICKUPS";
export const SET_BATTERY_TYPES = "firestore/SET_BATTERY_TYPES";
export const SET_DEALERS = "firestore/SET_DEALERS";
export const SET_RECEIVING_SITES = "firestore/SET_RECEIVING_SITES";
export const RESET_FIRESTORE_STORE = "auth/RESET_FIRESTORE_STORE";
export const SET_REPAIR_PARTS = "firestore/SET_REPAIR_PARTS";
export const SET_REPAIR_INVENTORY = "firestore/SET_REPAIR_INVENTORY";
export const SET_REPAIR_INVENTORY_SHIPMENTS =
  "firestore/SET_REPAIR_INVENTORY_SHIPMENTS";
export const SET_REPAIRS = "firestore/SET_REPAIRS";
export const SET_BATTERY_TYPE_LISTENER = "firestore/SET_BATTERY_TYPE_LISTENER";
export const SET_RECEIVING_SITES_LISTENER =
  "firestore/SET_RECEIVING_SITES_LISTENER";
export const SET_QUEUED_BATTERY_ORDERS = "firestore/SET_QUEUED_BATTERY_ORDERS";
export const SET_QUEUED_BATTERY_ORDERS_LISTENER =
  "firestore/SET_QUEUED_BATTERY_ORDERS_LISTENER";

// Report Reducer Types
export const SET_BILLING_HISTORY = "reports/SET_BILLING_HISTORY";
export const SET_COST_SUMMARY = "reports/SET_COST_SUMMARY";
export const TOGGLE_REPORT_MODAL = "reports/TOGGLE_REPORT_MODAL";
export const RESET_REPORT_DATA = "reports/RESET_REPORT_DATA";
export const SET_BATTERY_PICKUP_RESULTS = "reports/SET_BATTERY_PICKUP_RESULTS";
