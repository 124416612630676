import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { StyledPaper } from "components";
import { Link, useLocation } from "react-router-dom";
import { SwitchTransition, Transition } from "react-transition-group";

const HeaderButton = ({ index, path, title }) => {
  const location = useLocation();

  const selected = useMemo(() => {
    return location.pathname === path;
  }, [location.pathname, path]);
  const duration = useMemo(() => (selected ? 0 : 500), [selected]);
  const buttonProps = useMemo(
    () => ({
      component: selected ? undefined : Link,
      cursor: selected ? "not-allowed" : "pointer",
      key: path,
      to: selected ? undefined : path,
      variant: "outlined",
    }),
    [path, selected]
  );
  const buttonStyle = useCallback(
    (state) => {
      const defaultStyle = {
        marginLeft: index === 0 ? "0px" : "4px",
        transition: `all ${duration}ms ease-in-out`,
      };
      const enterColors = (selected) => ({
        color: selected ? "#ED1C24" : "#6D6E71",
        borderColor: selected ? "#ED1C24" : "#6D6E71",
        backgroundColor: selected ? "rgba(0, 0, 0, 0.04)" : "rgba(0, 0, 0, 0)",
      });
      const exitColors = (selected) => ({
        color: selected ? "#6D6E71" : "#ED1C24",
        borderColor: selected ? "#6D6E71" : "#ED1C24",
        backgroundColor: selected ? "rgba(0, 0, 0, 0)" : "rgba(0, 0, 0, 0.04)",
      });
      if (state === "entering")
        return { ...defaultStyle, ...enterColors(selected) };
      if (state === "entered")
        return { ...defaultStyle, ...enterColors(selected) };
      if (state === "exiting")
        return { ...defaultStyle, ...exitColors(selected) };
      if (state === "exited")
        return { ...defaultStyle, ...exitColors(selected) };
    },
    [duration, index, selected]
  );

  return useMemo(() => {
    return (
      <SwitchTransition>
        <Transition key={selected} appear={true} timeout={duration}>
          {(state) => (
            <Button {...buttonProps} style={{ ...buttonStyle(state) }}>
              {title}
            </Button>
          )}
        </Transition>
      </SwitchTransition>
    );
  }, [buttonProps, buttonStyle, duration, selected, title]);
};
HeaderButton.propTypes = {
  index: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const HeaderMenu = ({ buttonItems = [], icon, title }) => {
  const classes = useStyles();
  const headerLayout = useCallback(
    (buttonItems) => ({
      display: "flex",
      alignItems: "center",
      marginBottom: buttonItems?.length > 0 ? "16px" : "0px",
    }),
    []
  );
  const HeaderIcon = icon;

  return (
    <StyledPaper>
      <div style={{ ...headerLayout(buttonItems) }}>
        <HeaderIcon className={classes.headerIcon} />
        <Typography variant="h4" color="primary">
          {title}
        </Typography>
      </div>
      <div className={classes.buttonOverflow}>
        {buttonItems.map((item, index) => (
          <HeaderButton
            key={item.path}
            path={item.path}
            title={item.title}
            index={index}
          />
        ))}
      </div>
    </StyledPaper>
  );
};

const useStyles = makeStyles((theme) => ({
  buttonOverflow: { overflowX: "auto", whiteSpace: "nowrap" },
  headerIcon: {
    color: theme.palette.secondary.main,
    fontSize: "2.5rem",
    marginRight: "16px",
  },
}));
HeaderMenu.propTypes = {
  buttonItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ),
  icon: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
    PropTypes.node,
    PropTypes.elementType,
  ]).isRequired,
  title: PropTypes.string.isRequired,
};
export default React.memo(HeaderMenu);
