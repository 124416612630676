import { createStore, combineReducers, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";
import { auth, firestore, reports } from "./reducers";

// Import redux reducers
import AffiliateSitesReducers from "./affiliate-sites/reducers";
import AgGridReducers from "./agGrid/reducers";
import BatteryTypesReducers from "./battery-types/reducers";
import BatteryPickupsReducers from "./battery-pickups/reducers";
import DealershipsReducers from "./dealerships/reducers";
import ReceivingSitesReducers from "./receiving-sites/reducers";
import PortalUsersReducers from "./portal-users/reducers";
import RepairPartsReducers from "./repair-parts/reducers";
import UiReducers from "./ui/reducers";

// Initialize Redux Store
export const store = createStore(
  combineReducers({
    auth,
    firestore,
    reports,
    affiliateSites: AffiliateSitesReducers,
    agGrid: AgGridReducers,
    batteryTypes: BatteryTypesReducers,
    batteryPickups: BatteryPickupsReducers,
    dealerships: DealershipsReducers,
    portalUsers: PortalUsersReducers,
    receivingSites: ReceivingSitesReducers,
    repairParts: RepairPartsReducers,
    ui: UiReducers,
  }),
  applyMiddleware(ReduxThunk)
);
