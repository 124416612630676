import React from "react";
// import {Auth, Functions} from './config.js';
import {
  Assessment,
  Build,
  Call,
  LocalShipping,
  Settings,
  LocalOffer,
} from "@material-ui/icons";

// Lazy load routes to reduce main Webpack bundle size; Wrapped with React Suspense in MainView component;
import BatteryRepair from "./views/BatteryRepair"; // Not compatible with lazy loading
const Error = React.lazy(() => import("./views/Error/index"));
const BatteryPickups = React.lazy(() => import("./views/BatteryPickups/index"));
const BatteryPickupDetails = React.lazy(() =>
  import("./views/BatteryPickups/BatteryPickupDetails")
);
const AdminSettings = React.lazy(() => import("./views/AdminSettings/index"));
const BatteryInventory = React.lazy(() => import("./views/BatteryInventory"));
const Contact = React.lazy(() => import("./views/Contact/index"));
const RepairDetails = React.lazy(() => import("./views/RepairDetails"));
const Reports = React.lazy(() => import("./views/Reports"));

export const routes = [
  {
    component: () => <BatteryPickups />,
    exact: true,
    strict: true,
    path: "/portal/battery-pickups",
    pageTitle: "Battery Pickups",
  },
  {
    component: () => <BatteryPickupDetails />,
    exact: true,
    strict: true,
    path: "/portal/battery-pickups/pickup-details/:DocId",
    pageTitle: "Battery Pickup Details",
  },
  {
    component: () => <AdminSettings />,
    exact: false,
    strict: true,
    path: "/portal/admin-settings",
    pageTitle: "Admin Settings",
  },
  // {component: () => <BatteryPickupDetails />, exact: true, strict: true, path: '/portal/battery-pickup/:from/:id', pageTitle: 'Battery Pickups'},
  // {component: () => <BatteryPickupDetails />, exact: true, strict: true, path: '/portal/battery-inventory/repair-details/return-history/:id', pageTitle: 'Inventory Management'},
  {
    component: () => <Reports />,
    exact: true,
    strict: true,
    path: "/portal/reports",
    pageTitle: "Reports",
  },
  {
    component: () => <Contact />,
    exact: true,
    strict: true,
    path: "/portal/contact-support",
    pageTitle: "Contact Support",
  },
  {
    component: () => <BatteryRepair />,
    exact: true,
    strict: true,
    path: "/portal/battery-repair",
    pageTitle: "Battery Repairs",
  },
  {
    component: () => <RepairDetails />,
    exact: true,
    strict: true,
    path: "/portal/battery-repair/:RepairId",
    pageTitle: "Battery Repairs",
  },
  {
    component: () => <BatteryInventory />,
    exact: true,
    strict: true,
    path: "/portal/battery-inventory",
    pageTitle: "Battery Repairs",
  },
  {
    component: () => <RepairDetails />,
    exact: true,
    strict: true,
    path: "/portal/battery-inventory/repair-details/:RepairId",
    pageTitle: "Battery Repairs",
  },
  {
    component: () => <Error />,
    exact: true,
    strict: true,
    path: "/portal/error",
    pageTitle: "Error",
  },
  {
    component: () => <BatteryRepair />,
    exact: true,
    strict: true,
    path: "/portal/battery-repair",
    pageTitle: "Battery Repairs",
  },
];

export const menuItems = (currentUser) => {
  const items = [];

  const batteryPickupsItem = {
    icon: () => <LocalShipping />,
    text: "Battery Pickups",
    link: "/portal/battery-pickups",
  };
  if (
    ["Admin", "Affiliate", "Dealership", "HMA", "KBI"].includes(
      currentUser?.group
    )
  )
    items.push(batteryPickupsItem);

  const batteryRepairItem = {
    icon: () => <Build />,
    text: "Battery Repair",
    link: "/portal/battery-repair",
  };
  if (["Admin", "HMA", "KBI"].includes(currentUser?.group))
    items.push(batteryRepairItem);

  const batteryInventoryItem = {
    icon: () => <LocalOffer />,
    text: "Battery Inventory",
    link: "/portal/battery-inventory",
  };
  if (["Admin", "HMA", "KBI", "HMA Techline"].includes(currentUser?.group))
    items.push(batteryInventoryItem);

  const contactSupportIterm = {
    icon: () => <Call />,
    text: "Contact Support",
    link: "/portal/contact-support",
  };
  if (
    ["Admin", "Affiliate", "Dealership", "HMA Techline"].includes(
      currentUser?.group
    )
  )
    items.push(contactSupportIterm);

  const reportItem = {
    icon: () => <Assessment />,
    text: "Reports",
    link: "/portal/reports",
  };
  if (["Admin", "HMA", "KBI"].includes(currentUser?.group))
    items.push(reportItem);

  const adminSettingItem = {
    icon: () => <Settings />,
    text: "Admin Settings",
    link: "/portal/admin-settings",
  };
  if (["Admin", "HMA", "KBI"].includes(currentUser?.group))
    items.push(adminSettingItem);

  return items;
};
