import React, { Fragment, useEffect, useState, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, CircularProgress, Paper, Typography } from "@material-ui/core";
import { CancelPresentation } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { ErrorBoundary } from "components";
import { Auth, Functions } from "config.js";
import { useBackgroundImage } from "./hooks";
import jwt from "jsonwebtoken";
import logo from "media/xEVLogoTag.svg";

const SignInDealer = () => {
  const [authError, setAuthError] = useState(null);
  const { replace: historyReplace } = useHistory();
  const backgroundImage = useBackgroundImage();
  const { jwToken } = useParams();
  const styles = useStyles();
  const backgroundStyle = useMemo(
    () => ({
      backgroundSize: "cover",
      backgroundImage: `url(${backgroundImage})`,
      opacity: 0.7,
      height: "100vh",
      width: "100vw",
    }),
    [backgroundImage]
  );

  useEffect(() => {
    if (!jwToken) setAuthError("No Signin Token Provided.");
    else if (jwToken === "demo") setAuthError("demo");
    else if (jwToken) {
      Auth.signInWithCustomToken(jwToken)
        .then(async () => {
          const { claims, uid } = jwt.decode(jwToken);
          await Functions.httpsCallable("updateDealershipUser")({
            active: claims.isAuthenticated,
            corporateCode: claims.corporateCode,
            corporateName: claims.corporateName,
            displayName: `${claims.firstName} ${claims.lastName}`,
            group: claims.group,
            jobCode: claims.jobCode,
            jobTitle: claims.jobTitle,
            uid: uid,
          });
          if (claims.group === "HMA Techline")
            historyReplace("/portal/battery-inventory");
          else historyReplace("/portal/battery-pickup");
        })
        .catch((error) => {
          if (error.message === "Invalid assertion format.")
            setAuthError("Invalid Sign In Token Provided.");
          else if (
            error.message ===
            "The custom token format is incorrect. Please check the documentation."
          )
            setAuthError("Invalid Sign In Token Provided.");
          else if (
            error.message ===
            "Invalid assertion format. 3 dot separated segments required."
          )
            setAuthError("Invalid Sign In Token Provided.");
          else if (error.message === "TOKEN_EXPIRED")
            setAuthError("Sign In Token Has Expired.");
          else setAuthError({ authError: error.message });
        });
    }
  }, [historyReplace, jwToken]);

  return (
    <ErrorBoundary filePath="src/views/SignIn/SignInDealer.js">
      <div style={backgroundStyle} />
      <div className={styles.overlay} />
      <div className={styles.layout}>
        <Paper className={styles.paper} elevation={4}>
          <div className={styles.logoWrapper}>
            <img
              alt="EV Battery Returns Logo"
              src={logo}
              className={styles.logo}
            />
          </div>
          <Typography variant="h5">Hyundai Dealership</Typography>
          <Typography variant="h5">Single Sign-On</Typography>
          {(!authError || authError === "demo") && (
            <Fragment>
              <CircularProgress
                className={styles.progress}
                color="secondary"
                size="30%"
                thickness={2.5}
              />
              <Typography variant="h6">Please Be Patient</Typography>
              <Typography variant="subtitle1" gutterBottom>
                Checking Dealer Authentication
              </Typography>
            </Fragment>
          )}
          {authError && authError !== "demo" && (
            <Fragment>
              <CancelPresentation className={styles.error} color="secondary" />
              <Typography variant="h6">Authorization Error</Typography>
              <Typography variant="subtitle1">{authError}</Typography>
              <Button
                color="primary"
                style={{ margin: "16px" }}
                variant="outlined"
                onClick={() => (window.location = "https://hyundaidealer.com/")}
              >
                Go Back to hyundaidealer.com
              </Button>
            </Fragment>
          )}
        </Paper>
      </div>
    </ErrorBoundary>
  );
};

const useStyles = makeStyles((theme) => ({
  layout: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    justifyContent: "flex-start",
    overflowY: "auto",
    position: "absolute",
    top: 0,
    width: "100vw",
  },
  logo: {
    height: "100px",
    marginBottom: "24px",
    marginTop: "24px",
  },
  logoWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  overlay: {
    backgroundColor: "#ED1C24",
    opacity: 0.3,
    height: "100vh",
    width: "100vw",
    position: "absolute",
    top: 0,
  },
  paper: {
    border: "1px solid lightgray",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "400px",
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(6),
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  progress: {
    margin: theme.spacing(5),
  },
  error: {
    fontSize: "10rem",
    margin: theme.spacing(3),
  },
}));
export default React.memo(SignInDealer);
