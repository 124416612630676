import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { Collapse, Alert, Formik, Table } from "@kbi/component-library";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Grid,
  Typography,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { NonFormikSubmitButton } from "./NewRepairModal/";
import {
  CheckCircleOutline,
  Search,
  CheckBoxOutlined,
  CheckBoxOutlineBlank,
} from "@material-ui/icons";
import { Firestore } from "../../config.js";
import { createRepair } from "../../state/actions/firestore.js";
import * as yup from "yup";
const { FormikForm, TextField, FormButton } = Formik;

const NewRepairModal = ({ close, modal }) => {
  const [stage, setStage] = useState(0);
  const [matchingDocuments, setMatchingDocuments] = useState(null);
  const [selectedBattery, setSelectedBattery] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { currentUser } = useSelector((state) => state.auth);

  const dialogProps = {
    fullWidth: true,
    maxWidth: "md",
    open: modal?.type === "addRepair",
    scroll: "body",
    transitionDuration: { exit: 0 },
  };
  const handleClose = () => {
    setStage(0);
    setMatchingDocuments(null);
    setSelectedBattery(null);
    close();
  };
  const formikProps = {
    initialStatus: { alert: "" },
    initialValues: {
      serialNumber: "",
    },
    validationSchema: yup.object().shape({
      serialNumber: yup.string().required("Battery Serial Number is required."),
    }),
    onSubmit: async (values, actions) => {
      Firestore.collection("battery-pickups")
        .where("Battery.SerialNumber", "==", values.serialNumber)
        .get()
        .then((snap) => {
          actions.setSubmitting(false);

          if (snap.empty) {
            setMatchingDocuments(null);
            return actions.setStatus({
              alert:
                "That serial number has no matches. Please reenter serial number.",
            });
          }

          const arrayOfMatches = snap.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            DateReceived: doc.data().DateReceived.toDate(),
            DateRequested: doc.data().DateRequested.toDate(),
          }));
          actions.setStatus({ alert: "" });
          return setMatchingDocuments(arrayOfMatches);
        });
    },
  };

  const serialNumberField = {
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <IconButton type="submit">
            <Search />
          </IconButton>
        </InputAdornment>
      ),
    },
    name: "serialNumber",
    label: "Battery Serial No.",
  };
  const batterySelectTable = {
    columns: [
      { Header: "Date Received", accessor: "DateReceived", type: "datetime" },
      { Header: "Dealer No.", accessor: "Location.SiteId" },
      { Header: "Part Number", accessor: "Battery.PartNumber" },
      { Header: "Name", accessor: "Battery.Name" },
      { Header: "VIN", accessor: "Battery.VIN" },
    ],
    data: matchingDocuments ? matchingDocuments : [],
    sortInitial: [{ id: "DateReceived", desc: true }],
    actionsPerRow: [
      (rowData) => ({
        icon:
          rowData === selectedBattery ? CheckBoxOutlined : CheckBoxOutlineBlank,
        tooltip: rowData === selectedBattery ? "Deselect Row" : "Select Row",
        onClick: ({ rowData }) => {
          if (rowData !== selectedBattery) {
            setSelectedBattery(rowData);
          } else setSelectedBattery(null);
        },
      }),
    ],
    paginationShowEmpty: false,
  };
  const modalSubmitClick = async (setStatus) => {
    const dataForFirestore = {
      Active: true,
      Status: "Pending Data Upload",
      Battery: {
        ...selectedBattery.Battery,
      },
      BatteryPickup: {
        Id: selectedBattery.id,
        DealerCode: selectedBattery.Location.Code,
        DealerName: selectedBattery.Location.Name,
      },
      ReceivingVIN: null,
      RepairFinished: null,
      Mileage: null,
      TechlineFile: null,
      PreRepairFile: null,
      MidtronicsModuleFile: null,
      Notes: [],
      PostRepairFile: null,
      InstalledParts: null,
      RepairDescription: null,
      ConsumedShortNumbers: null,
      ShipmentOrdered: null,
      ShipmentLocation: null,
      ShipmentContact: null,
      ShipmentSent: null,
      ShipmentReceived: null,
      ShipmentOutboundDetails: null,
    };

    setIsSubmitting(true);
    const result = await createRepair(dataForFirestore, currentUser);
    if (result.success) {
      setIsSubmitting(false);
      setStatus({ alert: "" });
      setStage(stage + 1);
    } else {
      setIsSubmitting(false);
      setStatus({ alert: result.error });
    }
  };

  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formikProps}>
        {({ status, setStatus }) => (
          <Fragment>
            <DialogTitle>
              <Collapse in={stage !== 1}>Start New Battery Repair</Collapse>
            </DialogTitle>
            <DialogContent>
              <Collapse in={stage === 0}>
                <DialogContentText>
                  Select the battery that this repair is for:
                </DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField {...serialNumberField} />
                  </Grid>
                  {matchingDocuments && (
                    <Grid item xs={12}>
                      <div style={{ border: "solid 1px lightgray" }}>
                        <Table {...batterySelectTable} />
                      </div>
                    </Grid>
                  )}
                </Grid>
              </Collapse>
              <Collapse in={stage === 1}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <CheckCircleOutline style={{ fontSize: "10em" }} />
                  <Typography
                    variant="h6"
                    style={{ textAlign: "center" }}
                    gutterBottom
                  >
                    Battery repair successfully started.
                  </Typography>
                </div>
              </Collapse>
              <Alert
                in={Boolean(status.alert)}
                text={status.alert}
                severity="error"
              />
            </DialogContent>
            <Collapse in={stage !== 1}>
              <DialogActions>
                <div style={{ justifyContent: "space-between", width: "100%" }}>
                  <FormButton
                    onClick={handleClose}
                    color="secondary"
                    variant="text"
                  >
                    Cancel
                  </FormButton>
                  <div style={{ float: "right" }}>
                    <NonFormikSubmitButton
                      isSubmitting={isSubmitting}
                      color="primary"
                      variant="text"
                      type="button"
                      disabled={!selectedBattery}
                      onClick={() => modalSubmitClick(setStatus)}
                    >
                      Submit
                    </NonFormikSubmitButton>
                  </div>
                </div>
              </DialogActions>
            </Collapse>
            <Collapse in={stage === 1}>
              <DialogActions>
                <Button onClick={handleClose} color="primary" variant="text">
                  Close
                </Button>
              </DialogActions>
            </Collapse>
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

NewRepairModal.propTypes = {
  close: PropTypes.func.isRequired,
  modal: PropTypes.object.isRequired,
};

export default NewRepairModal;
