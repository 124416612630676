import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Button, CircularProgress } from "@material-ui/core";

const NonFormikSubmitButton = (props) => {
  const {
    children,
    color,
    disabled,
    endIcon,
    fullWidth,
    href,
    progressProps,
    size,
    startIcon,
    style,
    variant,
    isSubmitting,
    ...otherProps
  } = props;

  const progressSizeMemo = useMemo(() => {
    const buttonSize = size || undefined;
    if (buttonSize === "small") return 12;
    else return 24;
  }, [size]);

  const wrapperPropsMemo = useMemo(
    () => ({
      // Applying margin to div wrapper ensures proper placement of CircularProgress component.
      // Hardcoding buttonPropsMemo to {margin: 0} to ensure margin style only applied in wrapperPropsMemo.
      style: {
        position: "relative",
        display: "inline",
        margin: style?.margin || 0,
        marginTop: style?.marginTop || 0,
        marginRight: style?.marginRight || 0,
        marginBottom: style?.marginBottom || 0,
        marginLeft: style?.marginLeft || 0,
      },
    }),
    [style]
  );
  const buttonPropsMemo = useMemo(
    () => ({
      color,
      disabled: disabled || isSubmitting,
      disableRipple: true,
      endIcon: endIcon,
      fullWidth,
      href,
      size,
      startIcon,
      style: {
        ...style,
        // Applying margin to div wrapper ensures proper placement of CircularProgress component.
        // Hardcoding buttonPropsMemo to {margin: 0} to ensure margin style only applied in wrapperPropsMemo.
        margin: 0,
        marginTop: 0,
        marginRight: 0,
        marginBottom: 0,
        marginLeft: 0,
      },
      type: "submit",
      variant,
    }),
    [
      color,
      disabled,
      endIcon,
      fullWidth,
      href,
      size,
      startIcon,
      style,
      variant,
      isSubmitting,
    ]
  );
  const progressPropsMemo = useMemo(
    () => ({
      size: progressSizeMemo,
      ...progressProps,
      style: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -progressSizeMemo / 2,
        marginLeft: -progressSizeMemo / 2,
        ...progressProps?.style,
      },
    }),
    [progressProps, progressSizeMemo]
  );

  return (
    <div {...wrapperPropsMemo}>
      <Button {...buttonPropsMemo} {...otherProps}>
        {children}
      </Button>
      {isSubmitting && <CircularProgress {...progressPropsMemo} />}
    </div>
  );
};

NonFormikSubmitButton.defaultProps = {
  children: "Submit",
  color: "primary",
  disabled: false,
  fullWidth: false,
  size: "medium",
  variant: "contained",
  isSubmitting: false,
};
NonFormikSubmitButton.propTypes = {
  /** The text of the button (e.g. `<SubmitButton>{children}</SubmitButton>`) */
  children: PropTypes.node,
  /** The color of the component. It supports those theme colors that make sense for this component. */
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  /** If `true`, the button will be disabled. */
  disabled: PropTypes.bool,
  /** Element placed after the children */
  endIcon: PropTypes.node,
  /** If `true`, the button will take up the full width of its container. */
  fullWidth: PropTypes.bool,
  /** The URL to link to when the button is clicked. If defined, an `<a>` element will be used as the root node. */
  href: PropTypes.string,
  /** Pass props to SubmitButton's CircularProgress component. See <a href='https://material-ui.com/api/circular-progress/' target='_blank'>CircularProgress API</a> for details. */
  progressProps: PropTypes.object,
  /** The size of the button. `small` is equivalent to the dense button styling. */
  size: PropTypes.oneOf(["small", "medium", "large"]),
  /** Element placed before the children. */
  startIcon: PropTypes.node,
  /** An object to add CSS styles to Button component */
  style: PropTypes.object,
  /** The variant to use. */
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
  isSubmitting: PropTypes.bool.isRequired,
};
export default NonFormikSubmitButton;
