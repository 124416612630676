import React from "react"; // eslint-disable-line no-unused-vars
import {
  SET_AFFILIATES,
  SET_BATTERY_PICKUPS,
  SET_BATTERY_TYPES,
  SET_DEALERS,
  SET_REPAIR_PARTS,
  SET_REPAIRS,
  SET_BATTERY_TYPE_LISTENER,
} from "../types";
import {
  SET_RECEIVING_SITES,
  SET_REPAIR_INVENTORY,
  SET_RECEIVING_SITES_LISTENER,
  SET_QUEUED_BATTERY_ORDERS,
  SET_QUEUED_BATTERY_ORDERS_LISTENER,
} from "../types";
import { Firestore, Functions } from "../../config";
import { formatSingleLineAddress } from "@kbi/utility-library";
import moment from "moment";

// All firebase calls related to the "affiliates" collection.
const affiliateRef = Firestore.collection("affiliates");
const getAffiliateCollection = ({ currentUser, dispatch }) => {
  const options = { includeMetadataChanges: true };
  const query = (() => {
    // Query returns all affiliate docs for admins and techline, just one matching doc for affiliates, and null for dealership users.
    if (
      currentUser.group === "Admin" ||
      currentUser.group === "KBI" ||
      currentUser.group === "HMA" ||
      currentUser.group === "HMA Techline"
    ) {
      return affiliateRef;
    } else if (currentUser.group === "Affiliate") {
      return affiliateRef.where(
        "AffiliateCode",
        "==",
        currentUser.affiliateCode
      );
    } else return null;
  })();
  const handleQuerySnap = (snap) => {
    if (!snap.metadata.hasPendingWrites) {
      const list = [];
      for (let i = 0, size = snap.size; i < size; i++) {
        list.push({ ...snap.docs[i].data(), AffiliateSiteId: snap.docs[i].id });
      }
      dispatch({ type: SET_AFFILIATES, payload: list });
    }
  };
  const handleQueryError = (error) => {
    console.error("getAffiliateCollection Error: ", error);
  };
  if (query)
    return query.onSnapshot(options, handleQuerySnap, handleQueryError);
  else return null;
};

// All firebase calls related to the "battery-pickups" collection.
const batteryPickupsRef = Firestore.collection("battery-pickups");
const getBatteryPickupCollection = ({ currentUser, dispatch }) => {
  const list = [];
  const ref = {};
  const options = { includeMetadataChanges: true };
  const query = () => {
    if (
      currentUser.group === "Admin" ||
      currentUser.group === "KBI" ||
      currentUser.group === "HMA" ||
      currentUser.group === "HMA Techline"
    ) {
      return batteryPickupsRef;
    } else if (currentUser.group === "Affiliate") {
      return batteryPickupsRef.where(
        "Location.SiteId",
        "==",
        currentUser.affiliateId
      );
    } else if (currentUser.group === "Dealership") {
      return batteryPickupsRef.where(
        "Location.SiteId",
        "==",
        currentUser.corporateCode
      );
    }
  };
  const handleQuerySnap = async (snap) => {
    const snapSize = snap.docChanges().length;
    if (snapSize > 0) {
      const findDocIndex = (id) => {
        const foundIndex = list.findIndex((item) => {
          return item.BatteryPickupId === id;
        });
        return foundIndex;
      };
      for (let i = 0; i < snapSize; i++) {
        const { type, doc } = snap.docChanges()[i];
        const data = doc.data();
        const docData = {
          ...doc.data(),
          BatteryPickupId: doc.id,
          AdminReminder: data.AdminReminder
            ? data.AdminReminder.toDate()
            : null,
          DateReceived: data.DateReceived ? data.DateReceived.toDate() : null,
          DateRequested: data.DateRequested.toDate(),
          ShipmentDetails: data.ShipmentDetails
            ? {
                ...data.ShipmentDetails,
                PickupDate: data.ShipmentDetails.PickupDate.toDate(),
              }
            : null,
          Accounting: {
            ...data.Accounting,
            DateClosed: data.Accounting.DateClosed
              ? data.Accounting.DateClosed.toDate()
              : null,
          },
        };
        if (type === "added") {
          list.push(docData);
          ref[doc.id] = docData;
        }
        if (type === "modified") {
          list.splice(findDocIndex(doc.id), 1, docData);
          ref[doc.id] = docData;
        }
        if (type === "removed") {
          list.splice(findDocIndex(doc.id), 1);
          ref[doc.id] = docData;
        }
      }
    }
    // dispatch({ type: SET_BATTERY_PICKUPS, payload: { list, ref } });
    dispatch({
      type: SET_BATTERY_PICKUPS,
      payload: { list: [...list], ref: { ...ref } },
    });
  };
  const handleQueryError = (error) => {
    console.error("Error: ", error);
  };
  return query().onSnapshot(options, handleQuerySnap, handleQueryError);
};
export const addBatteryPickupDoc = async (newBatteryPickup) => {
  try {
    const newDocument = await batteryPickupsRef.add(newBatteryPickup);
    return { success: true, error: null, newDocument };
  } catch (error) {
    return { success: false, error: error.message };
  }
};
export const updateBatteryPickupDoc = async (docId, newBatteryPickup) => {
  try {
    const newDocument = await batteryPickupsRef
      .doc(docId)
      .update(newBatteryPickup);
    return { success: true, error: null, newDocument };
  } catch (error) {
    return { success: false, error: error.message };
  }
};
export const emailItapForShipment = async (newBatteryPickup) => {
  if (process.env.REACT_APP_ENV !== "production") return;

  const itapEmail = ["Rene@GoITAP.com"];
  const templateData = {
    dealerCode: newBatteryPickup.Location.Code,
    dealerName: newBatteryPickup.Location.Name,
    VIN: newBatteryPickup.Battery.VIN,
    partNumber: newBatteryPickup.Battery.PartNumber,
    carrier: newBatteryPickup.ShipmentDetails.Carrier,
    shippingDocNo: newBatteryPickup.ShipmentDetails.ShippingPaperNo,
    pickupDate: moment(newBatteryPickup.ShipmentDetails.PickupDate).format(
      "MM/DD/YYYY"
    ),
  };
  try {
    const newEmailDocument = await Firestore.collection("Emails").add({
      to: itapEmail,
      template: {
        name: "ItapShipmentScheduled",
        data: templateData,
      },
    });
    return { success: true, error: null, newEmailDocument };
  } catch (error) {
    return { success: false, error: error.message };
  }
};
export const emailRepairEngineerForRepair = async (
  pickupId,
  newBatteryPickup
) => {
  if (process.env.REACT_APP_ENV !== "production") return;

  const engineerEmail = ["plawson@hmausa.com"];
  const ccEmail = ["awood@kbirecycling.com", "mparra@kbirecycling.com"];
  const bccEmail = ["daniel@kbirecycling.com"];
  const templateData = {
    pickupId: pickupId,
    serialNumber: newBatteryPickup.Battery.SerialNumber,
  };
  try {
    const newEmailDocument = await Firestore.collection("Emails").add({
      to: engineerEmail,
      cc: ccEmail,
      bcc: bccEmail,
      template: {
        name: "RepairEngineerNotification",
        data: templateData,
      },
    });
    return { success: true, error: null, newEmailDocument };
  } catch (error) {
    return { success: false, error: error.message };
  }
};

// All firebase calls related to the "battery-types" collection.
const batteryTypesRef = Firestore.collection("battery-types");
export const getBatteryTypeCollection = (dispatch) => {
  const options = { includeMetadataChanges: true };
  const handleQuerySnap = (snap) => {
    if (!snap.metadata.hasPendingWrites) {
      const list = [];
      const ref = {};
      const active = [];
      for (let i = 0, size = snap.size; i < size; i++) {
        const data = {
          ...snap.docs[i].data(),
          BatteryTypeId: snap.docs[i].id,
          SubPartStringForTable:
            snap.docs[i].data().SubBatteryPartNumbers?.join(", ") || "",
        };
        list.push(data);
        ref[data.BatteryTypeId] = data;
        if (data.Active) active.push(data);
      }
      dispatch({ type: SET_BATTERY_TYPES, payload: { list, active, ref } });
    }
  };
  const handleQueryError = (error) => {
    console.error("Error occured", error);
  };
  const listener = batteryTypesRef.onSnapshot(
    options,
    handleQuerySnap,
    handleQueryError
  );
  dispatch({ type: SET_BATTERY_TYPE_LISTENER, payload: listener });
};

// All firebase calls related to the "dealerships" collection.
const dealershipsRef = Firestore.collection("dealerships");
const getdealershipsCollection = ({ currentUser, dispatch }) => {
  const options = { includeMetadataChanges: true };
  const query = (() => {
    // Query returns all dealer docs for admins and techline, just one matching doc for dealers, and null for affiliate users.
    if (
      currentUser.group === "Admin" ||
      currentUser.group === "KBI" ||
      currentUser.group === "HMA" ||
      currentUser.group === "HMA Techline"
    ) {
      return dealershipsRef;
    } else if (currentUser.group === "Dealership") {
      return dealershipsRef.where(
        "DealerCode",
        "==",
        currentUser.corporateCode
      );
    } else return null;
  })();
  const handleQuerySnap = (snap) => {
    if (!snap.metadata.hasPendingWrites) {
      const list = [];
      for (let i = 0, size = snap.size; i < size; i++) {
        list.push({ ...snap.docs[i].data(), DealerSiteId: snap.docs[i].id });
      }
      dispatch({ type: SET_DEALERS, payload: list });
      // setDealers(list);
    }
  };
  const handleQueryError = (error) => {
    console.error("chromeQuery Error: ", error);
  };
  if (query)
    return query.onSnapshot(options, handleQuerySnap, handleQueryError);
  else return null;
};

// All firebase calls related to the "receiving-sites" collection.
const receivingSiteRef = Firestore.collection("receiving-sites");
export const getReceivingSiteCollection = (dispatch) => {
  const options = { includeMetadataChanges: true };
  const handleQuerySnap = (snap) => {
    if (!snap.metadata.hasPendingWrites) {
      const active = [];
      const list = [];
      const ref = {};
      for (let i = 0, size = snap.size; i < size; i++) {
        const doc = snap.docs[i];
        const data = { ...doc.data(), ReceivingSiteId: doc.id };
        if (data.Active) active.push(data);
        list.push(data);
        ref[doc.id] = data;
      }
      dispatch({ type: SET_RECEIVING_SITES, payload: { active, list, ref } });
    }
  };
  const handleQueryError = (error) => {
    console.error("Receiving Site Error: ", error);
  };
  const listener = receivingSiteRef
    .orderBy("Name")
    .onSnapshot(options, handleQuerySnap, handleQueryError);
  dispatch({ type: SET_RECEIVING_SITES_LISTENER, payload: listener });
  return listener;
};

// All firebase calls related to the "repair-parts" collection.
const repairPartsRef = Firestore.collection("repair-parts");
const getRepairPartsCollection = ({ currentUser, dispatch }) => {
  const options = { includeMetadataChanges: true };
  const handleQuerySnap = (snap) => {
    if (!snap.metadata.hasPendingWrites) {
      const active = [];
      const list = [];
      const ref = {};
      for (let i = 0, size = snap.size; i < size; i++) {
        const doc = snap.docs[i];
        const data = { ...doc.data(), RepairPartId: doc.id };
        if (data.Active) active.push(data);
        list.push(data);
        ref[doc.id] = data;
      }
      dispatch({ type: SET_REPAIR_PARTS, payload: { active, list, ref } });
    }
  };
  const handleQueryError = (error) => {
    console.error("Repair Part Error: ", error);
  };
  return repairPartsRef.onSnapshot(options, handleQuerySnap, handleQueryError);
};

// All firebase calls related to the repair-inventory collection
const repairInventoryRef = Firestore.collection("repair-inventory");
const getRepairInventoryCollection = ({ currentUser, dispatch }) => {
  const options = { includeMetadataChanges: true };
  const handleQuerySnap = (snap) => {
    if (!snap.metadata.hasPendingWrites) {
      const active = [];
      const list = [];
      const ref = {};
      for (let i = 0, size = snap.size; i < size; i++) {
        const doc = snap.docs[i];
        const data = {
          ...doc.data(),
          RepairInventoryId: doc.id,
          DateReceived: doc.data().DateReceived.toDate(),
        };
        if (data.Active) active.push(data);
        list.push(data);
        ref[doc.id] = data;
      }
      dispatch({ type: SET_REPAIR_INVENTORY, payload: { active, list, ref } });
    }
  };
  const handleQueryError = (error) => {
    console.error("Repair Inventory Error: ", error);
  };
  return repairInventoryRef.onSnapshot(
    options,
    handleQuerySnap,
    handleQueryError
  );
};
export const updateRepairInventoryDoc = async (
  docId,
  newRepairInventory,
  currentUser
) => {
  try {
    await Functions.httpsCallable("updateRepairInventoryDoc")({
      docId,
      newRepairInventory,
      currentUser,
    });
    return { success: true, error: null };
  } catch (error) {
    return { success: false, error: error.message };
  }
};

export const createRepairPartOrder = (
  newRepairPartOrder,
  newRepairInventoryArray,
  fileData,
  currentUser
) => {
  const formData = new FormData();
  formData.append("newRepairPartOrder", JSON.stringify(newRepairPartOrder));
  formData.append(
    "newRepairInventoryArray",
    JSON.stringify(newRepairInventoryArray)
  );
  formData.append("fileData", fileData.file, fileData.fileName);
  formData.append("currentUser", JSON.stringify(currentUser));

  const urlToUse =
    window.location.hostname === "localhost"
      ? "http://us-central1-hma-portal-dev.cloudfunctions.net/addRepairPartOrder"
      : "https://us-central1-hma-tracking.cloudfunctions.net/addRepairPartOrder";

  return fetch(urlToUse, {
    method: "POST",
    body: formData,
  })
    .then((resp) => {
      if (!resp.ok) {
        throw new Error("There was an error with submission.");
      }
      return { success: true, error: null };
    })
    .catch((error) => {
      return { success: false, error: error.message };
    });
};

// All firebase calls related to queued-battery-orders collection
export const getQueuedBatteryOrders = (dispatch) => {
  const queuedOrdersRef = Firestore.collection("queued-battery-orders").where(
    "Active",
    "==",
    true
  );
  const options = { includeMetadataChanges: true };
  const handleQuerySnap = (snap) => {
    if (!snap.metadata.hasPendingWrites) {
      const list = [];
      const ref = {};
      for (let i = 0, size = snap.size; i < size; i++) {
        const doc = snap.docs[i];
        const data = {
          ...doc.data(),
          QueuedOrderId: doc.id,
          Date: doc.data().Date.toDate(),
        };
        list.push(data);
        ref[doc.id] = data;
      }
      dispatch({ type: SET_QUEUED_BATTERY_ORDERS, payload: { list, ref } });
    }
  };
  const handleQueryError = (error) => {
    console.error("Queued Battery Orders Error: ", error);
  };
  dispatch({
    type: SET_QUEUED_BATTERY_ORDERS_LISTENER,
    payload: queuedOrdersRef.onSnapshot(
      options,
      handleQuerySnap,
      handleQueryError
    ),
  });
};
export const createQueuedBatteryOrder = async (formData, currentUser) => {
  const newQueuedOrder = {
    Location: {
      Address1: formData.addressLine1,
      Address2: formData.addressLine2,
      City: formData.city,
      Code: formData.code.SiteId,
      Name: formData.name,
      PostalCode: formData.postalCode,
      SiteId: formData.code.SiteId,
      State: formData.state,
      ShipPhone: formData.code.ShipPhone,
    },
    RequestedBattery: {
      Number: formData.requestedBatteryNumber.PartNumber,
      Ref: formData.requestedBatteryNumber.BatteryTypeId,
    },
    ReceivingVIN: formData.receivingVIN,
  };
  try {
    await Functions.httpsCallable("addQueuedOrder")({
      newQueuedOrder,
      currentUser,
    });
    return { success: true, error: null };
  } catch (error) {
    return { success: false, error: error.message };
  }
};
export const deleteQueuedBatteryOrder = async (queuedOrder, currentUser) => {
  try {
    await Functions.httpsCallable("deleteQueuedOrder")({
      docId: queuedOrder.QueuedOrderId,
      QueuePosition: queuedOrder.QueuePosition,
      currentUser,
    });
    return { success: true, error: null };
  } catch (error) {
    return { success: false, error: error.message };
  }
};
export const fulfillQueuedBatteryOrder = async (
  dataForEndpoint,
  currentUser,
  docId
) => {
  const newQueuedOrder = {
    ...dataForEndpoint,
    emailTemplateName: "KbiRepairOrderNotification",
  };
  try {
    await Functions.httpsCallable("fulfillQueuedOrder")({
      newQueuedOrder,
      docId,
      currentUser,
    });
    return { success: true, error: null };
  } catch (error) {
    return { success: false, error: error.message };
  }
};
export const updateQueuePosition = async (
  dataForEndpoint,
  currentUser,
  docId
) => {
  const newQueuedOrder = {
    oldQueuePosition: dataForEndpoint.QueuePosition,
    newQueuePosition: dataForEndpoint.newQueuePosition,
  };

  try {
    await Functions.httpsCallable("updateQueuePosition")({
      ...newQueuedOrder,
      docId,
      currentUser,
    });
    return { success: true, error: null };
  } catch (error) {
    return { success: false, error: error.message };
  }
};

// All firebase calls related to repairs collection
const repairsRef = Firestore.collection("repairs");
const getRepairsCollection = ({ currentUser, dispatch }) => {
  const options = { includeMetadataChanges: true };
  const handleQuerySnap = (snap) => {
    if (!snap.metadata.hasPendingWrites) {
      const active = [];
      const list = [];
      const ref = {};
      for (let i = 0, size = snap.size; i < size; i++) {
        const doc = snap.docs[i];
        const data = {
          ...doc.data(),
          RepairId: doc.id,
          RepairStarted: doc.data().RepairStarted.toDate(),
          RepairFinished: doc.data().RepairFinished?.toDate() || null,
          ShipmentOrdered: doc.data().ShipmentOrdered?.toDate() || null,
          ShipmentSent: doc.data().ShipmentSent?.toDate() || null,
          ShipmentReceived: doc.data().ShipmentReceived?.toDate() || null,
        };
        if (data.TechlineFile) {
          data.TechlineFile.UploadedOn = data.TechlineFile.UploadedOn.toDate();
        }
        if (data.PreRepairFile) {
          data.PreRepairFile.UploadedOn =
            data.PreRepairFile.UploadedOn.toDate();
        }
        if (data.MidtronicsModuleFile) {
          data.MidtronicsModuleFile.UploadedOn =
            data.MidtronicsModuleFile.UploadedOn.toDate();
        }
        if (data.PostRepairFile) {
          data.PostRepairFile.UploadedOn =
            data.PostRepairFile.UploadedOn.toDate();
        }
        // data.Notes = data.Notes?.map(note => ({...note, CreatedOn: note.CreatedOn.toDate()}));
        if (data.Active) active.push(data);
        list.push(data);
        ref[doc.id] = data;
      }
      dispatch({ type: SET_REPAIRS, payload: { active, list, ref } });
    }
  };
  const handleQueryError = (error) => {
    console.error("Repairs Error: ", error);
  };
  return repairsRef.onSnapshot(options, handleQuerySnap, handleQueryError);
};
export const createRepair = async (newRepair, currentUser) => {
  try {
    const response = await Functions.httpsCallable("addRepair")({
      newRepair,
      currentUser,
    });
    return {
      success: true,
      RepairId: response.data?.dataToReturn.RepairId,
      error: null,
    };
  } catch (error) {
    return { success: false, error: error.message };
  }
};
export const updateRepair = async (
  docId,
  newRepair,
  currentUser,
  fileData = null,
  emailTemplateName = null
) => {
  const formData = new FormData();
  formData.append("newRepair", JSON.stringify(newRepair));
  formData.append("currentUser", JSON.stringify(currentUser));
  formData.append("docId", docId);
  if (emailTemplateName) {
    formData.append("emailTemplateName", emailTemplateName);
  }

  if (fileData) {
    formData.append("fileData", fileData.file, fileData.fileName);
  }

  const urlToUse =
    window.location.hostname === "localhost"
      ? "http://us-central1-hma-portal-dev.cloudfunctions.net/updateRepair"
      : "https://us-central1-hma-tracking.cloudfunctions.net/updateRepair";

  return fetch(urlToUse, {
    method: "POST",
    body: formData,
  })
    .then((resp) => {
      if (!resp.ok) {
        throw new Error("There was an error with submission.");
      }
      return { success: true, error: null };
    })
    .catch((error) => {
      return { success: false, error: error.message };
    });
};
export const addNoteToRepair = async (
  docId,
  newNote,
  currentUser,
  fileData = []
) => {
  const formData = new FormData();
  formData.append("newNote", JSON.stringify(newNote));
  formData.append("currentUser", JSON.stringify(currentUser));
  formData.append("docId", docId);

  for (let i = 0; i < fileData.length; i++) {
    const blob = fileData[i].buffer
      ? new Blob([fileData[i].buffer], { type: "image/jpg" })
      : fileData[i].file;
    formData.append("fileData", blob, fileData[i].fileName);
  }

  const urlToUse =
    window.location.hostname === "localhost"
      ? "http://us-central1-hma-portal-dev.cloudfunctions.net/addNoteToRepair"
      : "https://us-central1-hma-tracking.cloudfunctions.net/addNoteToRepair";

  return fetch(urlToUse, {
    method: "POST",
    body: formData,
  })
    .then((resp) => {
      if (!resp.ok) {
        throw new Error("There was an error with submission.");
      }
      return { success: true, error: null };
    })
    .catch((error) => {
      return { success: false, error: error.message };
    });
};
export const completeRepair = async (docId, newRepair, currentUser) => {
  try {
    await Functions.httpsCallable("markRepairAsComplete")({
      newRepair: JSON.stringify(newRepair),
      currentUser,
      docId,
    });
    emailForNewBatteryInInventory(newRepair);
    return { success: true, error: null };
  } catch (error) {
    return { success: false, error: error.message };
  }
};
export const decommissionRepair = async (docId, newRepair, currentUser) => {
  try {
    await Functions.httpsCallable("decommissionRepair")({
      newRepair: JSON.stringify(newRepair),
      currentUser,
      docId,
    });
    return { success: true, error: null };
  } catch (error) {
    return { success: false, error: error.message };
  }
};
async function emailForNewBatteryInInventory(newRepair) {
  if (process.env.REACT_APP_ENV !== "production") return;

  const repairShipmentEmails = ["plawson@hmausa.com"];
  const bccEmail = ["daniel@kbirecycling.com"];

  const templateData = {
    partNo: newRepair.Battery.PartNumber,
    partName: newRepair.Battery.Name,
    serialNo: newRepair.Battery.SerialNumber,
  };
  try {
    const newEmailDocument = await Firestore.collection("Emails").add({
      to: repairShipmentEmails,
      bcc: bccEmail,
      template: {
        name: "NewBatteryRepairCompleted",
        data: templateData,
      },
    });

    return { success: true, error: null, newEmailDocument };
  } catch (error) {
    console.log("error", error);
    return { success: false, error: error.message };
  }
}
export const emailForRepairShipment = async (
  newRepair,
  currentUser,
  docId = null
) => {
  if (process.env.REACT_APP_ENV !== "production") return;

  // eslint-disable-next-line max-len
  const repairShipmentEmails = [
    "plawson@hmausa.com",
    "joshuayou@hmausa.com",
    "dhuang@hmausa.com",
    "acummings@hmausa.com",
  ];
  const ccEmail = [
    "awood@kbirecycling.com",
    "lsegundo@kbirecycling.com",
    "mparra@kbirecycling.com",
  ];
  const bccEmail = ["daniel@kbirecycling.com"];

  const templateData = {
    orderedBy: currentUser.email,
    dealerCode: newRepair.ShipmentLocation.Code,
    dealerName: newRepair.ShipmentLocation.Name,
    dealerAddress: formatSingleLineAddress({
      Country: "United States",
      City: newRepair.ShipmentLocation.City,
      Line1: newRepair.ShipmentLocation.Address1,
      Line2: newRepair.ShipmentLocation.Address2,
      State: newRepair.ShipmentLocation.State,
      PostalCode: newRepair.ShipmentLocation.PostalCode,
    }),
    contactName: newRepair.ShipmentContact.Name,
    contactPhone: newRepair.ShipmentContact.Phone,
    contactEmail: newRepair.ShipmentContact.Email,
    receivingVIN: newRepair.ReceivingVIN,
    batteryName: newRepair.Battery.Name,
    batteryNo: newRepair.Battery.PartNumber,
    serialNo: newRepair.Battery.SerialNumber,
    shippedTo: newRepair.ShipmentLocation.Code,
    shippingPaperNo: newRepair.ShipmentOutboundDetails.ShippingPaperNo,
    carrier:
      newRepair.ShipmentOutboundDetails &&
      newRepair.ShipmentOutboundDetails.Carrier
        ? newRepair.ShipmentOutboundDetails.Carrier
        : "Undefined",
    ETADate: moment(newRepair.ShipmentSent).add(5, "days").format("MM/DD/YY"),
    pickupId: docId,
  };

  try {
    const newEmailDocument = await Firestore.collection("Emails").add({
      to: repairShipmentEmails,
      cc: ccEmail,
      bcc: bccEmail,
      template: {
        name: "RepairShipmentNotification",
        data: templateData,
      },
    });

    return { success: true, error: null, newEmailDocument };
  } catch (error) {
    console.log("error", error);
    return { success: false, error: error.message };
  }
};
export const emailForKbiNotification = async (
  newRepair,
  currentUser,
  docId = null
) => {
  if (process.env.REACT_APP_ENV !== "production") return;

  const notificationEmails = [
    "lsuarez@kinsbursky.com",
    "awood@kbirecycling.com",
    "jheroux@kbirecycling.com",
    "lsegundo@kbirecycling.com",
  ];
  const bccEmail = ["daniel@kbirecycling.com"];

  const templateData = {
    contactName: newRepair.ShipmentContact.Name,
    contactEmail: newRepair.ShipmentContact.Email,
    contactPhone: newRepair.ShipmentContact.Phone,
    dealerCode: newRepair.ShipmentLocation.Code,
    dealerName: newRepair.ShipmentLocation.Name,
    batteryName: newRepair.Battery.Name,
    batteryNo: newRepair.Battery.PartNumber,
    serialNo: newRepair.Battery.SerialNumber,
    repairId: docId,
  };
  try {
    const newEmailDocument = await Firestore.collection("Emails").add({
      to: notificationEmails,
      bcc: bccEmail,
      template: {
        name: "KbiRepairOrderNotification",
        data: templateData,
      },
    });

    return { success: true, error: null, newEmailDocument };
  } catch (error) {
    console.log("error", error);
    return { success: false, error: error.message };
  }
};

// All React View Listeners
export const listenAdminSettings =
  ({ currentUser, dispatch }) =>
  () => {
    if (currentUser.valid) {
      const affiliateCollectionListener = getAffiliateCollection({
        currentUser,
        dispatch,
      });
      const repairPartCollectionListener = getRepairPartsCollection({
        currentUser,
        dispatch,
      });
      const unsubscribe = () => {
        if (affiliateCollectionListener) affiliateCollectionListener();
        if (repairPartCollectionListener) repairPartCollectionListener();
      };
      return () => unsubscribe();
    }
  };
export const listenBatteryPickup =
  ({ currentUser, dispatch }) =>
  () => {
    if (currentUser.valid) {
      const affiliateCollectionListener = getAffiliateCollection({
        currentUser,
        dispatch,
      });
      const batteryPickupCollectionListener = getBatteryPickupCollection({
        currentUser,
        dispatch,
      });
      const dealershipCollectionListener = getdealershipsCollection({
        currentUser,
        dispatch,
      });
      const unsubscribe = () => {
        if (affiliateCollectionListener) affiliateCollectionListener();
        if (batteryPickupCollectionListener) batteryPickupCollectionListener();
        if (dealershipCollectionListener) dealershipCollectionListener();
      };
      return () => unsubscribe();
    }
  };
export const listenBatteryPickupDetails =
  ({ currentUser, dispatch }) =>
  () => {
    if (currentUser.valid) {
      const affiliateCollectionListener = getAffiliateCollection({
        currentUser,
        dispatch,
      });
      const batteryPickupCollectionListener = getBatteryPickupCollection({
        currentUser,
        dispatch,
      });
      const dealershipCollectionListener = getdealershipsCollection({
        currentUser,
        dispatch,
      });
      const unsubscribe = () => {
        if (affiliateCollectionListener) affiliateCollectionListener();
        if (batteryPickupCollectionListener) batteryPickupCollectionListener();
        if (dealershipCollectionListener) dealershipCollectionListener();
      };
      return () => unsubscribe();
    }
  };
export const listenRepairInventory =
  ({ currentUser, dispatch }) =>
  () => {
    if (currentUser.valid) {
      const affiliateCollectionListener = getAffiliateCollection({
        currentUser,
        dispatch,
      });
      const dealershipCollectionListener = getdealershipsCollection({
        currentUser,
        dispatch,
      });
      const repairInventoryListener = getRepairInventoryCollection({
        currentUser,
        dispatch,
      });
      const repairsListener = getRepairsCollection({ currentUser, dispatch });
      const unsubscribe = () => {
        if (affiliateCollectionListener) affiliateCollectionListener();
        if (dealershipCollectionListener) dealershipCollectionListener();
        if (repairInventoryListener) repairInventoryListener();
        if (repairsListener) repairsListener();
      };
      return () => unsubscribe();
    }
  };
