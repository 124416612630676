import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";
import { Button, LinearProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { QRCode } from "react-qr-svg";
import { ErrorRedirect } from "components";
import { Firestore } from "config";

const StockLabel = () => {
  const [repairPartlabel, setRepairPartlabel] = useState({
    data: null,
    pending: true,
  });
  const [error, setError] = useState(null);
  const classes = useStyles();
  const { DocId } = useParams();

  useEffect(() => {
    (async () => {
      const doc = await Firestore.doc(`repair-inventory/${DocId}`).get();
      if (!doc.exists) setError("Repair part label not found.");
      else
        setRepairPartlabel({
          data: { ...doc.data(), DocId: doc.id },
          pending: false,
        });
    })();
  }, [DocId]);

  if (error) return <ErrorRedirect error={error} path="/portal" />;
  if (repairPartlabel.pending) return <LinearProgress />;
  return (
    <Fragment>
      <Button
        variant="outlined"
        className={classes.printBtn}
        onClick={() => window.print()}
      >
        Print
      </Button>
      <div className={classes.labelBlock}>
        <div className={classes.labelFlex}>
          {repairPartlabel?.data && (
            <PrintPart
              part={repairPartlabel.data}
              flex="row"
              DocId={DocId}
              qrWidth="35%"
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export function PrintPart({
  part,
  DocId: _DocId,
  flex = "column",
  qrWidth = "210px",
}) {
  const classes = useStyles();

  const DocId = _DocId ?? part.id;
  if (!DocId) {
    return <div />;
  }
  return (
    <div className={classes.primary} style={{ flexDirection: flex }}>
      <div className={classes.primaryQR} style={{ width: qrWidth }}>
        <QRCode value={part.id} />
      </div>
      <div className={classes.primaryText}>
        <Typography
          variant="body1"
          align="start"
          className={classes.primaryTextNormal}
        >
          {part["@PartName"]?.Value}
        </Typography>
        <Typography
          variant="body1"
          align="start"
          className={classes.primaryTextNormal}
        >
          ID: {DocId}
        </Typography>
        <Typography
          variant="body1"
          align="start"
          className={classes.primaryTextNormal}
        >
          P/N: {part["@PartNumber"]?.Value}
        </Typography>
        <Typography
          variant="body1"
          align="start"
          className={classes.primaryTextNormal}
        >
          Received:{" "}
          {part.DateReceived
            ? DateTime.fromMillis(part.DateReceived.toMillis()).toLocaleString(
                DateTime.DATE_SHORT
              )
            : "Missing"}
        </Typography>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  printBtn: {
    margin: "16px 0px",
    "@media print": { display: "none" },
  },
  labelBlock: {
    border: "1px solid lightgray",
    padding: "0.1in",
    width: "4in",
    pageBreakAfter: "always",
    breakInside: "avoid",
    pageBreakInside: "avoid",
    "@media print": { border: "0px" },
  },
  labelFlex: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-evenly",
  },
  primary: {
    display: "flex",
    alignItems: "flex-start",
  },
  primaryQR: {
    marginRight: "8px",
  },
  primaryText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    width: "90%",
    alignItems: "flex-start",
  },
  primaryTextBold: {
    fontWeight: 500,
    lineHeight: 1.3,
  },
  primaryTextNormal: {
    fontWeight: 400,
    lineHeight: 1.3,
  },
  secondaryTextBold: { fontWeight: 700 },
  secondaryTextNormal: { fontWeight: 500 },
}));
export default React.memo(StockLabel);
