import { useEffect, useState } from "react";
import { createApi } from "unsplash-js";
const unsplash = createApi({
  accessKey: "ZbvY6Z6GiE-uftQcQu11usJUKdYIgt3N_SO0s1aO2tE",
  fetch,
});

const useBackgroundImage = () => {
  const [backgroundImage, setBackgroundImage] = useState(null);

  // Determine screen width and thereby the best background image size for user's device
  const screenWidth = window.screen.width;
  const backgroundSize = (() => {
    if (screenWidth >= 1920) return "1600x900";
    else if (screenWidth >= 1280) return "1280x720";
    else if (screenWidth >= 960) return "960x540";
    else return "640x360";
  })();

  useEffect(() => {
    unsplash.photos
      .get({ photoId: "_PDndqdsA3o" })
      .then((image) => {
        setBackgroundImage(image.response.urls.full);
      })
      .catch(() => {
        setBackgroundImage(undefined);
      });
  }, [backgroundSize]);

  return backgroundImage;
};

export default useBackgroundImage;
