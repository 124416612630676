import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Storage } from "@material-ui/icons";
import { Functions } from "config";

const ImportItem = ({ classes }) => {
  const handleImport = useCallback(async () => {
    alert("Import started. Check console for status updates.");
    console.log("Start delete of development database...");
    try {
      const response = await Functions.httpsCallable(
        "deleteDevelopmentDatabase",
        {}
      )();
      if (response.data.success) {
        console.log("Successfully deleted development database...");
        console.log("Starting import of production database...");
        const database = await Functions.httpsCallable(
          "importProductionDatabase",
          {}
        )();
        console.log("Production database import response: ", database);
        const storage = await Functions.httpsCallable(
          "importProductionStorage",
          {}
        )();
        console.log("Production storage import response: ", storage);
        return;
      } else
        throw new Error(
          "An error occured while trying to import production data."
        );
    } catch (error) {
      alert(error.message);
      console.log(error.message);
    }
  }, []);

  return (
    <ListItem
      button
      classes={{ root: classes.listItem }}
      onClick={handleImport}
    >
      <ListItemIcon classes={{ root: classes.listItemIcon }}>
        <Storage />
      </ListItemIcon>
      <ListItemText
        primary={"Import Production Data"}
        classes={{ primary: classes.listItemTextPrimary }}
      />
    </ListItem>
  );
};

ImportItem.propTypes = { classes: PropTypes.object.isRequired };
export default React.memo(ImportItem);
