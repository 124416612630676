import React, { forwardRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { acSetDrawerOpen } from "state/ui/actions.js";

const MenuItem = ({ classes, icon, link, text }) => {
  const smallDevice = useSelector((state) => state.ui.smallDevice);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const CustomLink = useMemo(() => {
    const handleClick = (e) => {
      e.preventDefault();
      // Prevents same path being added to history multiple times.
      if (location.pathname !== link) {
        history.push(link);
        // Close Drawer, on small devices only, when new menu item link is clicked.
        if (smallDevice) dispatch(acSetDrawerOpen(false));
      }
    };
    // Makes ListItem function more like <a /> tag.
    return forwardRef((linkProps, ref) => (
      <Link ref={ref} to={link} {...linkProps} onClick={handleClick} />
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location.pathname, link, smallDevice]);

  return (
    <ListItem
      button
      disableRipple
      component={CustomLink}
      classes={{ root: classes.listItem }}
      selected={location.pathname.lastIndexOf(link, 0) === 0}
    >
      <ListItemIcon classes={{ root: classes.listItemIcon }}>
        {icon}
      </ListItemIcon>
      <ListItemText
        primary={text}
        classes={{ primary: classes.listItemTextPrimary }}
      />
    </ListItem>
  );
};

MenuItem.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.node.isRequired,
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
export default React.memo(MenuItem);
