import {
  SET_BILLING_HISTORY,
  SET_COST_SUMMARY,
  SET_BATTERY_PICKUP_RESULTS,
  TOGGLE_REPORT_MODAL,
  RESET_REPORT_DATA,
} from "../types";

const INITIAL_STATE = {
  data: null,
  modal: false,
  type: null,
  batteryRepairs: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_BILLING_HISTORY:
      return { ...state, data: payload, modal: false, type: "Billing History" };
    case SET_COST_SUMMARY:
      return { ...state, data: payload, modal: false, type: "Cost Summary" };
    case SET_BATTERY_PICKUP_RESULTS:
      return { ...state, data: payload, modal: false, type: "Battery Repairs" };
    case TOGGLE_REPORT_MODAL:
      return { ...state, modal: !state.modal };
    case RESET_REPORT_DATA:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
