import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { acGetDealerships } from "state/dealerships/actions";

const useDealerships = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.auth);
  const { all, ref, pending, touched } = useSelector(
    (state) => state.dealerships
  );

  useEffect(() => {
    if (!touched && currentUser) dispatch(acGetDealerships(currentUser));
  }, [currentUser, dispatch, touched]);

  return { pending, touched, all, ref };
};

export default useDealerships;
