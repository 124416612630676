import React from "react";
import { LinearProgress, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const MainViewProgress = () => {
  const classes = useStyles();
  const deviceIsExtraSmall = useMediaQuery("(max-width:600px)");

  if (deviceIsExtraSmall)
    return (
      <LinearProgress
        color="secondary"
        className={classes.linearProgressSmall}
      />
    );
  return (
    <LinearProgress
      color="secondary"
      className={classes.linearProgressNormal}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  linearProgressSmall: { margin: "-16px -16px 0px -16px" },
  linearProgressNormal: { margin: "-24px -24px 0px -32px" },
}));
export default React.memo(MainViewProgress);
