import React from "react";
import { Paper } from "@material-ui/core";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";

const StyledTablePaper = (props) => {
  const { children, ...rest } = props;
  const isPrintView = useMediaQuery({ query: "print" });
  return (
    <Paper elevation={isPrintView ? 0 : 3} {...rest}>
      {children}
    </Paper>
  );
};

StyledTablePaper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.func,
    PropTypes.object,
    PropTypes.string,
  ]),
};
export default React.memo(StyledTablePaper);
