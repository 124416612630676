import { useEffect } from "react";
import { useMediaQuery } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { acSetDrawerOpen, acSetSmallDevice } from "state/ui/actions.js";

// Check device size and set initual UI state for Drawer open status and MainView marginLeft style.
const useSmallDeviceCheck = () => {
  const dispatch = useDispatch();
  const smallDevice = useSelector((state) => state.ui.smallDevice);
  const deviceIsSmall = useMediaQuery("(max-width:1680px)");

  useEffect(() => {
    if (deviceIsSmall) {
      dispatch(acSetDrawerOpen(false));
      dispatch(acSetSmallDevice(true));
    } else {
      dispatch(acSetDrawerOpen(true));
      dispatch(acSetSmallDevice(false));
    }
  }, [deviceIsSmall, dispatch]);

  return { smallDevice };
};

export default useSmallDeviceCheck;
