import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { Collapse, Alert, Formik } from "@kbi/component-library";
import { shortNumberGenerator } from "@kbi/utility-library";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Grid,
  Typography,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { Add, Clear, CheckCircleOutline } from "@material-ui/icons";
import { FieldArray } from "formik";
import get from "lodash.get";
import { createRepairPartOrder } from "../../state/actions/firestore.js";
import Dropzone from "react-dropzone";
import * as yup from "yup";
import moment from "moment";
import { Firestore } from "config.js";
const {
  FormikForm,
  TextField,
  SubmitButton,
  FormButton,
  DateField,
  AutoCompleteObject,
  CurrencyField,
  NumberField,
  validateAutoObject,
} = Formik;
validateAutoObject();
yup.addMethod(
  yup.object,
  "uniqueProperty",
  function (propertyName, message, fieldName) {
    return this.test("unique", message, function (value) {
      // eslint-disable-line no-invalid-this
      if (!value || !get(value, propertyName)) {
        return true;
      }

      if (
        this.parent // eslint-disable-line no-invalid-this
          .filter((v) => v !== value)
          .some((v) => get(v, propertyName) === get(value, propertyName))
      ) {
        throw this.createError({
          // eslint-disable-line no-invalid-this
          path: `${this.path}.${fieldName || propertyName}`, // eslint-disable-line no-invalid-this
        });
      }

      return true;
    });
  }
);

const NewPartsModal = ({ close, modal }) => {
  const [stage, setStage] = useState(0);
  const [fileState, setFileState] = useState(null);
  const [arrayOfIds, setArrayOfIds] = useState(null);
  const { repairParts, repairInventory } = useSelector(
    (state) => state.firestore
  );
  const { currentUser } = useSelector((state) => state.auth);

  const dialogProps = {
    fullWidth: true,
    maxWidth: "md",
    open: modal?.type === "addInventory",
    scroll: "body",
    transitionDuration: { exit: 0 },
    className: "HidePrint",
  };
  const handleBack = (status, setStatus) => {
    setStage(stage - 1);
    setStatus({ error: "", file: status.file });
  };
  const handleClose = () => {
    setStage(0);
    setFileState(null);
    setArrayOfIds(null);
    close(arrayOfIds);
  };
  const createOrderData = (values, inventoryIds) => ({
    ShipmentNumber: values.shipmentNumber,
    ItemsCreated: inventoryIds,
    ItemData: values.items.map((item) => ({
      PartNumber: item.partNumber.Number,
      PartName: item.partName,
      UnitPrice: parseFloat(item.unitPrice),
      Quantity: parseInt(item.quantity),
    })),
    CreatedBy: {
      Name: currentUser.displayName,
      Uid: currentUser.uid,
    },
    DateReceived: moment(values.dateReceived, "YYYY-MM-DD")
      .set({ hours: 12, minutes: 0 })
      .format("YYYY-MM-DD HH:mm"),
  });
  const createInventoryData = (values) => {
    const arrayOfItems = [];
    values.items.forEach((item) => {
      for (let i = 0; i < parseInt(item.quantity); i++) {
        arrayOfItems.push({
          Active: true,
          "@PartNumber": {
            "@": "repair-parts",
            DocId: item.partNumber.RepairPartId,
            Value: item.partNumber.Number,
          },
          "@EolFee": {
            "@": "repair-parts",
            DocId: item.partNumber.RepairPartId,
            Value: item.partNumber.EolFee,
          },
          "@PartName": {
            "@": "repair-parts",
            DocId: item.partNumber.RepairPartId,
            Value: item.partName,
          },
          "@PNC": {
            "@": "repair-parts",
            DocId: item.partNumber.RepairPartId,
            Value: item.partNumber.PNC,
          },
          UsedInRepairRef: null,
          UnitPrice: item.unitPrice,
          ShipmentNumber: values.shipmentNumber,
          DateReceived: moment(values.dateReceived, "YYYY-MM-DD")
            .set({ hours: 12, minute: 0 })
            .format("YYYY-MM-DD HH:mm"),
        });
      }
    });
    const arrayOfIds = createUniqueIdArray(arrayOfItems.length);
    return arrayOfItems.map((item, index) => ({
      ...item,
      id: arrayOfIds[index],
    }));
  };
  const createUniqueIdArray = (numberOfItemsCreated) => {
    const set = new Set();
    while (set.size !== numberOfItemsCreated) {
      const uniqueId = shortNumberGenerator();
      if (!repairInventory.ref[uniqueId]) {
        set.add(uniqueId);
      }
    }
    return [...set];
  };
  const formikProps = {
    initialStatus: { alert: "", file: "" },
    initialValues: {
      dateReceived: moment().format("YYYY-MM-DD"),
      shipmentNumber: "",
      items: [
        {
          partNumber: "",
          partName: "",
          unitPrice: "",
          quantity: "",
        },
      ],
    },
    validationSchema: () => {
      if (stage === 0) {
        return yup.object().shape({
          dateReceived: yup
            .date()
            .required("Date Received is a required field.")
            .max(new Date(), "Date Received cannot be in the future."),
          shipmentNumber: yup
            .string()
            .required("Shipment Number is a required field."),
          items: yup.array().of(
            yup
              .object()
              .shape({
                partNumber: yup
                  .object()
                  .nullable()
                  .exists("Part Number is a required field."),
                partName: yup
                  .string()
                  .required("Part Name is a required field."),
                unitPrice: yup
                  .number()
                  .required("Unit Price is a required field.")
                  .min(0.01, "Unit Price must be a positive number."),
                quantity: yup
                  .number()
                  .required("Quantity is a required field.")
                  .min(1, "Quantity must be a positive number."),
              })
              .uniqueProperty(
                "partNumber.RepairPartId",
                "This Part Number is already on the form.",
                "partNumber"
              )
          ),
        });
      } else return yup.object().shape({});
    },
    onSubmit: (values, actions) => {
      if (stage === 0) {
        actions.setSubmitting(false);
        setStage(stage + 1);
      } else if (stage === 1 && !fileState) {
        actions.setStatus({
          alert: "You must upload a file before continuing.",
          file: "",
        });
        actions.setSubmitting(false);
      } else {
        const inventoryData = createInventoryData(values);
        const orderData = createOrderData(
          values,
          inventoryData.map((item) => item.id)
        );
        createRepairPartOrder(orderData, inventoryData, fileState, currentUser)
          .then(() => {
            return Promise.all(
              inventoryData.map((item) => {
                return Firestore.doc(`repair-inventory/${item.id}`).get(
                  item.id
                );
              })
            );
          })
          .then((dataArray) => {
            setArrayOfIds(
              dataArray.map((data, i) => {
                return {
                  ...data.data(),
                  DocId: inventoryData[i].id,
                  id: inventoryData[i].id,
                };
              })
            );
            actions.setSubmitting(false);
            actions.setStatus({ alert: "", file: "" });
            setStage(stage + 1);
          })
          .catch((error) => {
            actions.setStatus({ alert: error.message, file: "" });
            actions.setSubmitting(false);
          });
      }
    },
  };
  const fieldProps = {
    partNumber: (index) => ({
      name: `items[${index}].partNumber`,
      label: "Part Number",
      options: repairParts?.active
        ? repairParts.active.sort((a, b) => {
            if (a.Number.toUpperCase() < b.Number.toUpperCase()) return -1;
            if (a.Number.toUpperCase() > b.Number.toUpperCase()) return 1;
            return 0;
          })
        : [],
      optionKey: "Number",
      required: true,
      onChange: ({ field, form }) => {
        form.setFieldValue(`items[${index}].partName`, field.value?.Name || "");
      },
    }),
  };
  const dropzoneProps = {
    divStyles: {
      style: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      },
    },
    fileDrop: {
      style: {
        alignItems: "center",
        backgroundColor: "aliceblue",
        border: "2px gray dotted",
        display: "flex",
        height: "200px",
        justifyContent: "center",
      },
    },
    fileZone: (setStatus) => ({
      accept: "application/pdf",
      maxSize: 5242880,
      minSize: 1024,
      multiple: false,
      onDropAccepted: (file) => {
        const fileName = file[0].name;
        setStatus({
          alert: "",
          file: `The file ${fileName} has been uploaded.`,
        });
        setFileState({
          file: file[0],
          fileName: fileName,
        });
      },
      onDropRejected: (fileRejections, event) => {
        if (fileRejections[0].file.size < 1024) {
          setStatus({
            alert: "The file you selected was too small.",
            file: "",
          });
        } else if (fileRejections[0].file.size > 5242880) {
          setStatus({
            alert: "The file you selected was too large.",
            file: "",
          });
        } else {
          setStatus({ alert: "The file type you must be a PDF.", file: "" });
        }
      },
    }),
  };

  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formikProps}>
        {({ status, values, setStatus }) => (
          <Fragment>
            <DialogTitle>
              <Collapse in={stage !== 2}>Add Parts To Inventory</Collapse>
            </DialogTitle>
            <DialogContent>
              <Collapse in={stage === 0}>
                <DialogContentText>
                  Enter information for all parts received:
                </DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <DateField
                      name="dateReceived"
                      label="Date Received"
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="shipmentNumber"
                      label="Shipment Number"
                      required
                    />
                  </Grid>
                  <FieldArray name="items">
                    {(arrayHelpers) => (
                      <Fragment>
                        <Grid
                          item
                          xs={12}
                          container
                          spacing={2}
                          style={{
                            margin: "0px",
                            border: "1px solid lightgrey",
                          }}
                        >
                          {values.items.map((itemFields, index, self) => (
                            <Fragment key={`items[${index}]`}>
                              <Grid item xs={3}>
                                <AutoCompleteObject
                                  {...fieldProps.partNumber(index)}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <TextField
                                  name={`items[${index}].partName`}
                                  label="Part Name"
                                  disabled
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <CurrencyField
                                  name={`items[${index}].unitPrice`}
                                  label="Unit Price"
                                  required
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <NumberField
                                  name={`items[${index}].quantity`}
                                  label="Quantity"
                                  required
                                />
                              </Grid>
                              <Grid item xs={1} container alignItems="center">
                                <Grid item xs={12}>
                                  {
                                    <FormButton
                                      color="secondary"
                                      variant="text"
                                      disabled={self.length === 1}
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <Clear />
                                    </FormButton>
                                  }
                                </Grid>
                              </Grid>
                            </Fragment>
                          ))}
                        </Grid>
                        <Grid item xs={3}>
                          <FormButton
                            color="primary"
                            variant="text"
                            onClick={() =>
                              arrayHelpers.push({
                                partNumber: "",
                                partName: "",
                                unitPrice: "",
                                quantity: "",
                              })
                            }
                          >
                            <Add style={{ marginRight: "8px" }} />
                            Add Part
                          </FormButton>
                        </Grid>
                      </Fragment>
                    )}
                  </FieldArray>
                </Grid>
              </Collapse>
              <Collapse in={stage === 1}>
                <DialogContentText>Upload packing list file:</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Dropzone {...dropzoneProps.fileZone(setStatus)}>
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} {...dropzoneProps.fileDrop}>
                          <input {...getInputProps()} />
                          <div {...dropzoneProps.divStyles}>
                            <Typography variant="subtitle1">
                              Drop Packing List File Here or Click Inside Box to
                              Select
                            </Typography>
                            <Typography variant="body2">
                              (File Must be a PDF Under 5mb)
                            </Typography>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </Grid>
                </Grid>
              </Collapse>
              <Collapse in={stage === 2}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <CheckCircleOutline style={{ fontSize: "10em" }} />
                  <Typography
                    variant="h6"
                    style={{ textAlign: "center" }}
                    gutterBottom
                  >
                    Repair inventory shipment successfully created.
                  </Typography>
                </div>
              </Collapse>
              <Alert
                in={Boolean(status.alert)}
                text={status.alert}
                severity="error"
              />
              <Alert
                in={Boolean(status.file)}
                text={status.file}
                severity="success"
              />
            </DialogContent>
            <Collapse in={stage !== 2}>
              <DialogActions>
                <div style={{ justifyContent: "space-between", width: "100%" }}>
                  <FormButton
                    onClick={handleClose}
                    color="secondary"
                    variant="text"
                  >
                    Cancel
                  </FormButton>
                  <div style={{ float: "right" }}>
                    <FormButton
                      onClick={() => handleBack(status, setStatus)}
                      color="primary"
                      variant="text"
                      disabled={stage === 0}
                    >
                      Back
                    </FormButton>
                    <SubmitButton color="primary" variant="text">
                      {stage === 0 ? "Next" : "Submit"}
                    </SubmitButton>
                  </div>
                </div>
              </DialogActions>
            </Collapse>
            <Collapse in={stage === 2}>
              <DialogActions>
                <Button onClick={handleClose} color="primary" variant="text">
                  Close And Print Labels
                </Button>
              </DialogActions>
            </Collapse>
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

NewPartsModal.propTypes = {
  close: PropTypes.func.isRequired,
  modal: PropTypes.object.isRequired,
};

export default NewPartsModal;
