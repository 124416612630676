import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { Collapse, Alert, Formik } from "@kbi/component-library";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Grid,
  Typography,
  MenuItem,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { CheckCircleOutline } from "@material-ui/icons";
import { updateRepairInventoryDoc } from "../../state/actions/firestore.js";
import * as yup from "yup";
const {
  FormikForm,
  TextField,
  SubmitButton,
  FormButton,
  AutoCompleteObject,
  CurrencyField,
  validateAutoObject,
  SelectField,
} = Formik;
validateAutoObject();

const AdminEditItemModal = ({ close, modal }) => {
  const [stage, setStage] = useState(0);
  const repairParts = useSelector((state) => state.firestore.repairParts);
  const { currentUser } = useSelector((state) => state.auth);

  const dialogProps = {
    fullWidth: true,
    maxWidth: "md",
    open: modal?.type === "adminEdit",
    scroll: "body",
    transitionDuration: { exit: 0 },
  };
  const handleClose = () => {
    setStage(0);
    close();
  };
  const formikProps = {
    initialStatus: { alert: "", file: "" },
    initialValues: {
      active: modal.selectedItem?.Active,
      partNumber:
        repairParts.active.find(
          (part) => part.Number === modal.selectedItem?.["@PartNumber"].Value
        ) || "",
      partName: modal.selectedItem?.["@PartName"].Value,
      unitPrice: modal.selectedItem?.UnitPrice,
      pnc: modal.selectedItem?.["@PNC"].Value,
    },
    validationSchema: yup.object().shape({
      active: yup.boolean().required("Active is a required field."),
      partNumber: yup
        .object()
        .nullable()
        .exists("Part Number is a required field."),
      partName: yup.string().required("Part Number is a required field."),
      unitPrice: yup
        .number()
        .required("Unit Price is a required field.")
        .min(0.01, "Unit Price must be greater than $0.01."),
      pnc: yup.string().required("PNC is a required field."),
    }),
    onSubmit: async (values, actions) => {
      const newInventoryData = {
        Active: values.active,
        "@PartNumber": {
          "@": "repair-parts",
          DocId: values.partNumber.RepairPartId,
          Value: values.partNumber.Number,
        },
        "@PartName": {
          "@": "repair-parts",
          DocId: values.partNumber.RepairPartId,
          Value: values.partName,
        },
        "@EolFee": {
          "@": "repair-parts",
          DocId: values.partNumber.RepairPartId,
          Value: values.partNumber.EolFee,
        },
        "@PNC": {
          "@": "repair-parts",
          DocId: values.partNumber.RepairPartId,
          Value: values.pnc,
        },
        UnitPrice: values.unitPrice,
      };
      const response = await updateRepairInventoryDoc(
        modal.selectedItem.RepairInventoryId,
        newInventoryData,
        currentUser
      );
      if (response.success) {
        actions.setStatus({ alert: "" });
        setStage(stage + 1);
      } else {
        actions.setStatus({ alert: response.error });
        actions.setSubmitting(false);
      }
    },
  };
  const partNumberField = {
    name: "partNumber",
    label: "Part Number",
    options: repairParts?.active || [],
    optionKey: "Number",
    required: true,
    onChange: ({ field, form }) => {
      if (field.value === null) form.setFieldValue("partNumber", "");
      form.setFieldValue("partName", field.value?.Name || "");
      form.setFieldValue("pnc", field.value?.PNC || "");
    },
  };

  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formikProps}>
        {({ status }) => (
          <Fragment>
            <DialogTitle>
              <Collapse in={stage !== 1}>Admin Edit Item</Collapse>
            </DialogTitle>
            <DialogContent>
              <Collapse in={stage === 0}>
                <DialogContentText>
                  Edit the details of an item:
                </DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <SelectField name="active" label="Active" required>
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={6}>
                    <AutoCompleteObject {...partNumberField} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="partName"
                      label="Part Name"
                      required
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField name="pnc" label="PNC" required disabled />
                  </Grid>
                  <Grid item xs={6}>
                    <CurrencyField
                      name="unitPrice"
                      label="Unit Price"
                      required
                    />
                  </Grid>
                </Grid>
              </Collapse>
              <Collapse in={stage === 1}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <CheckCircleOutline style={{ fontSize: "10em" }} />
                  <Typography
                    variant="h6"
                    style={{ textAlign: "center" }}
                    gutterBottom
                  >
                    Repair inventory item successfully updated.
                  </Typography>
                </div>
              </Collapse>
              <Alert
                in={Boolean(status.alert)}
                text={status.alert}
                severity="error"
              />
            </DialogContent>
            <Collapse in={stage !== 1}>
              <DialogActions>
                <div style={{ justifyContent: "space-between", width: "100%" }}>
                  <FormButton
                    onClick={handleClose}
                    color="secondary"
                    variant="text"
                  >
                    Cancel
                  </FormButton>
                  <div style={{ float: "right" }}>
                    <SubmitButton color="primary" variant="text">
                      Submit
                    </SubmitButton>
                  </div>
                </div>
              </DialogActions>
            </Collapse>
            <Collapse in={stage === 1}>
              <DialogActions>
                <Button onClick={handleClose} color="primary" variant="text">
                  Close
                </Button>
              </DialogActions>
            </Collapse>
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

AdminEditItemModal.propTypes = {
  close: PropTypes.func.isRequired,
  modal: PropTypes.object.isRequired,
};

export default AdminEditItemModal;
