import {
  SET_PENDING,
  SET_TOUCHED,
  SET_BATTERY_PICKUPS,
  FORGET_BATTERY_PICKUPS,
} from "./types";
import { Auth, Firestore } from "config";
const snapOptions = { includeMetadataChanges: true };
const findDocIndex = (array, id) =>
  array.findIndex((item) => item.DocId === id);
const collectionRef = Firestore.collection("battery-pickups");

const batteryPickupList = [];
const batteryPickupRef = {};
let batteryPickupsUnsubscribe = null;
export const acGetBatteryPickups = (currentUser) => (dispatch, getState) => {
  // Used to prevent additional executions; This AC should only run when touched property is false;
  dispatch({ type: SET_TOUCHED, payload: true });

  // Use Auth listener to prevent unauthorized access from non-users; Also clears data when user logs off;
  Auth.onAuthStateChanged((user) => {
    if (user) {
      let queryRef = null;

      // Return entire battery-pickups collection when user has a management level role...
      if (["Admin", "KBI", "HMA", "HMA Techline"].includes(currentUser.group))
        queryRef = collectionRef;
      // Otherwise return docs specifically related to the Affiliate/Dealership location
      else if (currentUser.group === "Affiliate")
        queryRef = collectionRef.where(
          "Location.SiteId",
          "==",
          currentUser.affiliateId
        );
      else if (currentUser.group === "Dealership")
        queryRef = collectionRef.where(
          "Location.SiteId",
          "==",
          currentUser.corporateCode
        );

      // If no query ref (should never happen), then alert user of issue and return to prvent snapshot execution
      if (!queryRef) {
        alert(
          `User role "${currentUser.group}" is not authorized to view battery pickups data.`
        );
        return;
      }

      const handleSnap = (snap) => {
        const docChangeSize = snap.docChanges().length;
        const isPending = getState().batteryPickups.pending;

        // If collection is empty and redux state is pending, then set pending to false to show first snap performed
        if (docChangeSize === 0 && isPending)
          dispatch({ type: SET_PENDING, payload: false });
        // If 1 or more doc change in snap, then loop through changes and dispatch to redux state
        else if (docChangeSize >= 1) {
          for (let i = 0; i < docChangeSize; i++) {
            const { type, doc } = snap.docChanges()[i];
            const docData = {
              ...doc.data(),
              DocId: doc.id,
              BatteryPickupId: doc.id,
            };
            if (type === "added") batteryPickupList.push(docData);
            if (type === "modified")
              batteryPickupList.splice(
                findDocIndex(batteryPickupList, doc.id),
                1,
                docData
              );
            if (type === "removed")
              batteryPickupList.splice(
                findDocIndex(batteryPickupList, doc.id),
                1
              );
            batteryPickupRef[doc.id] = docData;
          }
          const batteryPickups = {
            all: [...batteryPickupList],
            open: batteryPickupList.filter(
              (item) => item.Accounting.Status === "Open"
            ),
            closed: batteryPickupList.filter(
              (item) => item.Accounting.Status === "Closed"
            ),
            ref: batteryPickupRef,
          };
          dispatch({ type: SET_BATTERY_PICKUPS, payload: batteryPickups });
          dispatch({ type: SET_PENDING, payload: false });
        }
      };
      const handleError = (error) =>
        console.log("acGetBatteryPickups Error: ", error.message, { error });
      batteryPickupsUnsubscribe = queryRef.onSnapshot(
        snapOptions,
        handleSnap,
        handleError
      );
    } else {
      dispatch({ type: FORGET_BATTERY_PICKUPS });
      if (batteryPickupsUnsubscribe) batteryPickupsUnsubscribe();
    }
  });
};
