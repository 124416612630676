import {
  SET_PENDING,
  SET_TOUCHED,
  SET_AFFILIATE_SITES,
  FORGET_AFFILIATE_SITES,
} from "./types";
import { Auth, Firestore } from "config";
const snapOptions = { includeMetadataChanges: true };
const findDocIndex = (array, id) =>
  array.findIndex((item) => item.DocId === id);
const collectionRef = Firestore.collection("affiliates");

const affiliateSiteList = [];
const affiliateSiteRef = {};
let affiliateSiteUnsubscribe = null;
export const acGetAffiliateSites = (currentUser) => (dispatch, getState) => {
  // Used to prevent additional executions; This AC should only run when touched property is false;
  dispatch({ type: SET_TOUCHED, payload: true });

  // Use Auth listener to prevent unauthorized access from non-users; Also clears data when user logs off;
  Auth.onAuthStateChanged((user) => {
    if (user) {
      let queryRef = null;

      // Return entire affiliates collection when user has a management level role...
      if (["Admin", "KBI", "HMA", "HMA Techline"].includes(currentUser.group))
        queryRef = collectionRef;
      // else when user is an Affiliate return docs specifically related to that Affiliate user...
      else if (currentUser.group === "Affiliate")
        queryRef = collectionRef.where(
          "AffiliateCode",
          "==",
          currentUser.affiliateCode
        );
      // else when user is a Dealership return early and set pending to false since query of affiliate collection not needed
      else if (currentUser.group === "Dealership") {
        dispatch({ type: SET_PENDING, payload: false });
        return;
      }

      const handleSnap = (snap) => {
        const docChangeSize = snap.docChanges().length;
        const isPending = getState().affiliateSites.pending;

        // If collection is empty and redux state is pending, then set pending to false to show first snap performed
        if (docChangeSize === 0 && isPending)
          dispatch({ type: SET_PENDING, payload: false });
        // If 1 or more doc change in snap, then loop through changes and dispatch to redux state
        else if (docChangeSize >= 1) {
          for (let i = 0; i < docChangeSize; i++) {
            const { type, doc } = snap.docChanges()[i];
            const docData = {
              ...doc.data(),
              DocId: doc.id,
              AffiliateSiteId: doc.id,
            };
            if (type === "added") affiliateSiteList.push(docData);
            if (type === "modified")
              affiliateSiteList.splice(
                findDocIndex(affiliateSiteList, doc.id),
                1,
                docData
              );
            if (type === "removed")
              affiliateSiteList.splice(
                findDocIndex(affiliateSiteList, doc.id),
                1
              );
            affiliateSiteRef[doc.id] = docData;
          }
          const affiliateSites = {
            all: [...affiliateSiteList],
            active: affiliateSiteList.filter((item) => item.Active),
            inactive: affiliateSiteList.filter((item) => !item.Active),
            ref: affiliateSiteRef,
          };
          dispatch({ type: SET_AFFILIATE_SITES, payload: affiliateSites });
          dispatch({ type: SET_PENDING, payload: false });
        }
      };
      const handleError = (error) =>
        console.log("acGetAffiliateSites Error: ", error.message, { error });
      affiliateSiteUnsubscribe = queryRef.onSnapshot(
        snapOptions,
        handleSnap,
        handleError
      );
    } else {
      dispatch({ type: FORGET_AFFILIATE_SITES });
      if (affiliateSiteUnsubscribe) affiliateSiteUnsubscribe();
    }
  });
};
