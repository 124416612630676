import React, { Suspense } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { LinearProgress } from "@material-ui/core";

// Lazy load routes to reduce main Webpack bundle size
const RepairLabel = React.lazy(() => import("./RepairLabel"));
const RepairPartLabel = React.lazy(() => import("./RepairPartLabel"));
const ReturnCertificate = React.lazy(() => import("./ReturnCertificate"));

const Print = () => {
  return (
    <Suspense fallback={<LinearProgress color="secondary" />}>
      <Switch>
        <Route
          exact={true}
          strict={true}
          path="/print/repair-label/:DocId"
          component={RepairLabel}
        />
        ;
        <Route
          exact={true}
          strict={true}
          path="/print/repair-part-label/:DocId"
          component={RepairPartLabel}
        />
        ;
        <Route
          exact={true}
          strict={true}
          path="/print/return-certificate/:DocId"
          component={ReturnCertificate}
        />
        ;
        <Redirect exact to={"/portal"} />
      </Switch>
    </Suspense>
  );
};

export default Print;
