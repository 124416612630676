import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { matchPath, useLocation } from "react-router-dom";
import { Typography } from "@material-ui/core";

const Title = ({ routes }) => {
  const location = useLocation();
  const currentRoute = useMemo(() => {
    return (
      routes.find((route, index, array) => {
        return matchPath(location.pathname, {
          path: route.path,
          exact: route.exact,
          strict: route.strict,
        });
      }) || ""
    );
  }, [location.pathname, routes]);

  return (
    <Typography variant="h6" color="inherit" style={{ flexGrow: 1 }}>
      {currentRoute.pageTitle || ""}
    </Typography>
  );
};

Title.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.func.isRequired,
      exact: PropTypes.bool.isRequired,
      strict: PropTypes.bool.isRequired,
      path: PropTypes.string.isRequired,
      pageTitle: PropTypes.string.isRequired,
    })
  ),
};
export default React.memo(Title);
