import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { acGetBatteryPickups } from "state/battery-pickups/actions";

const useBatteryPickups = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.auth);
  const { all, open, closed, ref, pending, touched } = useSelector(
    (state) => state.batteryPickups
  );

  useEffect(() => {
    if (!touched && currentUser) dispatch(acGetBatteryPickups(currentUser));
  }, [currentUser, dispatch, touched]);

  return { pending, touched, all, open, closed, ref };
};

export default useBatteryPickups;
