import {
  SET_DRAWER_OPEN,
  SET_MODAL,
  SET_SMALL_DEVICE,
  SET_SNACKBAR,
} from "./types";

const INITIAL_STATE = {
  drawerOpen: null,
  modal: { item: null, open: false, stage: "initial", target: "" },
  smallDevice: null,
  snackbar: { message: "", open: false },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action;
  switch (type) {
    case SET_DRAWER_OPEN:
      return { ...state, drawerOpen: payload };
    case SET_MODAL:
      return { ...state, modal: { ...state.modal, ...payload } };
    case SET_SMALL_DEVICE:
      return { ...state, smallDevice: payload };
    case SET_SNACKBAR:
      return { ...state, snackbar: { ...state.snackbar, ...payload } };
    default:
      return state;
  }
};
