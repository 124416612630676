import {
  SET_PENDING,
  SET_TOUCHED,
  SET_BATTERY_TYPES,
  FORGET_BATTERY_TYPES,
} from "./types";
import { Auth, Firestore } from "config";
const snapOptions = { includeMetadataChanges: true };
const findDocIndex = (array, id) =>
  array.findIndex((item) => item.DocId === id);

const batteryTypeList = [];
const batteryTypeRef = {};
let batteryTypeUnsubscribe = null;
export const acGetBatteryTypes = (dispatch, getState) => {
  // Used to prevent additional executions; This AC should only run when touched property is false;
  dispatch({ type: SET_TOUCHED, payload: true });

  // Use Auth listener to prevent unauthorized access from non-users; Also clears data when user logs off;
  Auth.onAuthStateChanged((user) => {
    if (user) {
      const collectionRef = Firestore.collection("battery-types");
      const handleSnap = (snap) => {
        const docChangeSize = snap.docChanges().length;
        const isPending = getState().batteryTypes.pending;

        // If collection is empty and redux state is pending, then set pending to false to show first snap performed
        if (docChangeSize === 0 && isPending)
          dispatch({ type: SET_PENDING, payload: false });
        // If 1 or more doc change in snap, then loop through changes and dispatch to redux state
        else if (docChangeSize >= 1) {
          for (let i = 0; i < docChangeSize; i++) {
            const { type, doc } = snap.docChanges()[i];
            const docData = {
              ...doc.data(),
              DocId: doc.id,
              BatteryTypeId: doc.id,
            };
            if (type === "added") batteryTypeList.push(docData);
            if (type === "modified")
              batteryTypeList.splice(
                findDocIndex(batteryTypeList, doc.id),
                1,
                docData
              );
            if (type === "removed")
              batteryTypeList.splice(findDocIndex(batteryTypeList, doc.id), 1);
            batteryTypeRef[doc.id] = docData;
          }
          const batteryTypes = {
            all: [...batteryTypeList],
            active: batteryTypeList.filter((item) => item.Active),
            inactive: batteryTypeList.filter((item) => !item.Active),
            ref: batteryTypeRef,
          };
          dispatch({ type: SET_BATTERY_TYPES, payload: batteryTypes });
          dispatch({ type: SET_PENDING, payload: false });
        }
      };
      const handleError = (error) =>
        console.log("acGetBatteryTypes Error: ", error.message, { error });
      batteryTypeUnsubscribe = collectionRef.onSnapshot(
        snapOptions,
        handleSnap,
        handleError
      );
    } else {
      dispatch({ type: FORGET_BATTERY_TYPES });
      if (batteryTypeUnsubscribe) batteryTypeUnsubscribe();
    }
  });
};
