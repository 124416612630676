import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppBar as MuiAppBar, Toolbar, IconButton } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { acSetDrawerOpen } from "state/ui/actions.js";
import { Title } from "./AppBar/";
import { routes } from "routes";

const AppBar = () => {
  const drawerOpen = useSelector((state) => state.ui.drawerOpen);
  const classes = useStyles();

  // Component Action Creators
  const dispatch = useDispatch();
  const setDrawerOpen = useCallback(
    (drawerOpen) => {
      return () => dispatch(acSetDrawerOpen(drawerOpen));
    },
    [dispatch]
  );

  return (
    <MuiAppBar position="fixed" className={classes.appBar}>
      <Toolbar disableGutters>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          style={{ marginLeft: 8 }}
          onClick={setDrawerOpen(!drawerOpen)}
        >
          <MenuIcon />
        </IconButton>
        <Title routes={routes} />
        {/* <WarehouseSelector />*/}
      </Toolbar>
    </MuiAppBar>
  );
};
/*
const WarehouseSelector = () => {
  const warehouses = useWarehouses();
  const {whPrimary, whSecondary} = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };
  const Address = warehouses.active[selectedIndex].Address;

  return (
    <Hidden xsDown>
      <List component='nav' style={{padding: '0px'}}>
        <ListItem button onClick={handleClickListItem} dense>
          <ListItemText
            primary='Active Warehouse'
            secondary={`${Address.City}, ${Address.State} - ${Address.Street1}`}
            classes={{primary: whPrimary, secondary: whSecondary}}
          />
        </ListItem>
      </List>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {warehouses.active.map((option, index) => (
          <MenuItem key={option.DocId} selected={index === selectedIndex} onClick={(event) => handleMenuItemClick(event, index)}>
            {`${option.Address.City}, ${option.Address.State} - ${option.Address.Street1}`}
          </MenuItem>
        ))}
      </Menu>
    </Hidden>
  );
};
*/

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.primary.dark,
    zIndex: theme.zIndex.drawer + 1,
    "@media print": {
      display: "none",
      width: 0,
      height: 0,
      overflow: "hidden",
      lineHeight: 0,
      whiteSpace: "nowrap",
    },
  },
  whPrimary: {
    color: "white",
    fontSize: "1rem",
  },
  whSecondary: {
    color: "white",
  },
}));
export default React.memo(AppBar);
