import {
  SET_DRAWER_OPEN,
  SET_MODAL,
  SET_SMALL_DEVICE,
  SET_SNACKBAR,
} from "./types";

export const acSetDrawerOpen = (drawerOpen) => {
  return { type: SET_DRAWER_OPEN, payload: drawerOpen };
};

export const acSetModal = (modal) => {
  return { type: SET_MODAL, payload: modal };
};

export const acSetSmallDevice = (smallDevice) => {
  return { type: SET_SMALL_DEVICE, payload: smallDevice };
};

export const acSetSnackbar = (snackbar) => {
  return { type: SET_SNACKBAR, payload: snackbar };
};
