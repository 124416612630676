import { SET_CURRENT_USER } from "../types";

export const acSetCurrentUser = (claims) => {
  const currentUser = {};
  const buildKbiUser = () => {
    currentUser.valid = true;
    currentUser.email = claims.email;
    currentUser.displayName = claims.name;
    currentUser.group = claims.group;
    currentUser.uid = claims.user_id;
  };
  const buildAffiliateUser = () => {
    currentUser.valid = true;
    currentUser.affiliateCode = claims.affiliateCode;
    currentUser.affiliateId = claims.affiliateId;
    currentUser.email = claims.email;
    currentUser.displayName = claims.name;
    currentUser.group = claims.group;
    currentUser.uid = claims.user_id;
  };
  const buildDealershipUser = () => {
    currentUser.valid = true;
    currentUser.corporateCode = claims.corporateCode;
    currentUser.corporateName = claims.corporateName;
    currentUser.firstName = claims.firstName ? claims.firstName : "undefined";
    currentUser.isAuthenticated = claims.isAuthenticated;
    currentUser.jobCode = claims.jobCode;
    currentUser.jobTitle = claims.jobTitle;
    currentUser.lastName = claims.lastName ? claims.lastName : "undefined";
    currentUser.displayName = claims.name ? claims.name : "undefined";
    currentUser.group = claims.group;
    currentUser.uid = claims.user_id;
  };
  const buildHmaTechlineUser = () => {
    currentUser.valid = true;
    currentUser.corporateCode = claims.corporateCode || "";
    currentUser.corporateName = claims.corporateName || "";
    currentUser.firstName = claims.firstName ? claims.firstName : "undefined";
    currentUser.isAuthenticated = claims.isAuthenticated;
    currentUser.jobCode = claims.jobCode || "";
    currentUser.jobTitle = claims.jobTitle || "";
    currentUser.lastName = claims.lastName ? claims.lastName : "undefined";
    currentUser.displayName = claims.name ? claims.name : "undefined";
    currentUser.group = claims.group || "";
    currentUser.uid = claims.user_id;
  };
  if (["Admin", "KBI", "HMA"].includes(claims.group)) buildKbiUser();
  if (claims.group === "Affiliate") buildAffiliateUser();
  if (claims.group === "Dealership") buildDealershipUser();
  if (claims.group === "HMA Techline") buildHmaTechlineUser();
  return { type: SET_CURRENT_USER, payload: currentUser };
};
