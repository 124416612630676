import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormikForm,
  SubmitButton,
  PasswordField,
  TextField,
} from "@kbi/story-forms";
import { object, string } from "yup";
import { Auth } from "config.js";
import logo from "media/xEVLogoTag.svg";
import { z } from "zod";

const ForgotPassword = ({ stage, setStage }) => {
  const forgotPasswordBtnProps = useMemo(
    () => ({
      gutterBottom: true,
      style: {
        cursor: "pointer",
        float: "right",
        marginTop: "4px",
      },
      variant: "caption",
      onClick: () => {
        if (stage === "SignIn") setStage("ForgotPassword");
        else if (stage === "ForgotPassword") setStage("SignIn");
      },
    }),
    [stage, setStage]
  );
  return (
    <Typography {...forgotPasswordBtnProps}>
      {stage === "SignIn" && "Forgot Password?"}
      {stage === "ForgotPassword" && "Remembered Password?"}
    </Typography>
  );
};
ForgotPassword.propTypes = {
  stage: PropTypes.string.isRequired,
  setStage: PropTypes.func.isRequired,
};

const isDev = process.env.REACT_APP_ENV !== "production";

const envParse = z
  .object({
    REACT_APP_ENV: z.enum(["development", "production"]),
  })
  .safeParse(process.env);

const validEnv = envParse.success;

if (!envParse.success) {
  console.log(`Invalid environment variables:`);
  console.log(envParse.error);
}

const SignInUserForm = ({ view }) => {
  const [stage, setStage] = useState("SignIn");
  const styles = useStyles();
  const { replace: historyReplace } = useHistory();
  const formProps = useMemo(
    () => ({
      initialStatus: { text: "", in: false, severity: "warning" },
      initialValues: { Email: "", Password: "" },
      validationSchema: () => {
        const forgotPasswordValidation = object().shape({
          Email: string().required().email(),
        });
        const signInValidation = object().shape({
          Email: string().required().email(),
          Password: string().required().min(5),
        });
        if (stage === "ForgotPassword") return forgotPasswordValidation;
        if (stage === "SignIn") return signInValidation;
      },
      onSubmit: (values, actions) => {
        const handleSignIn = async () => {
          let awaitSuccess = true;
          await Auth.signInWithEmailAndPassword(
            values.Email,
            values.Password
          ).catch((error) => {
            awaitSuccess = false;
            actions.setStatus({ alert: error.message });
            actions.setSubmitting(false);
          });
          if (awaitSuccess) historyReplace("/portal");
        };
        const handleForgotPassword = async () => {
          let awaitSuccess = true;
          await Auth.sendPasswordResetEmail(values.Email).catch((error) => {
            awaitSuccess = false;
            actions.setStatus({ alert: error.message });
          });
          if (awaitSuccess)
            actions.setStatus({
              alert: "We have sent you a password reset email.",
            });
          actions.setSubmitting(false);
        };
        if (stage === "SignIn") handleSignIn(values, actions);
        else if (stage === "ForgotPassword")
          handleForgotPassword(values, actions);
      },
    }),
    [historyReplace, stage]
  );

  return (
    <div className={styles.layout}>
      <Paper className={styles.paper} elevation={4}>
        <div className={styles.logoWrapper}>
          <img
            alt="EV Battery Returns Logo"
            src={logo}
            className={styles.logo}
          />
        </div>
        <Typography variant="h5" align="center" className={styles.headerText}>
          Hyundai Battery Management
        </Typography>
        <Typography
          variant="h5"
          align="center"
          className={styles.headerText}
          gutterBottom
        >
          Administrator Login {`${isDev ? ` (Dev)` : ""}`}
        </Typography>
        {!validEnv && (
          <Typography
            variant="h5"
            align="center"
            className={styles.headerText}
            gutterBottom
          >
            Invalid .env, check logs
          </Typography>
        )}

        <FormikForm {...formProps}>
          {(formik) => (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField name="Email" required autoComplete="username" />
              </Grid>
              {stage === "SignIn" && (
                <Grid item xs={12}>
                  <PasswordField
                    name="Password"
                    required
                    autoComplete="current-password"
                  />
                </Grid>
              )}
              {Boolean(formik.status.text) && (
                <Grid item xs={12}>
                  <Alert
                    severity={formik.status.severity || "info"}
                    style={{ margin: "8px 0px" }}
                  >
                    {formik.status.text}
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12}>
                <div>
                  <SubmitButton fullWidth>
                    {stage === "SignIn" && "Sign In"}
                    {stage === "ForgotPassword" && "Reset Password"}
                  </SubmitButton>
                  <ForgotPassword stage={stage} setStage={setStage} />
                </div>
              </Grid>
            </Grid>
          )}
        </FormikForm>
      </Paper>
      <Paper className={styles.paper} elevation={4}>
        <Typography
          variant="h6"
          align="center"
          className={styles.headerText}
          gutterBottom
        >
          Hyundai Dealership Login
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => (window.location = "https://hyundaidealer.com/")}
        >
          Navigate to hyundaidealer.com
        </Button>
      </Paper>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  headerText: {
    fontSize: "1.2rem",
  },
  layout: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    justifyContent: "flex-start",
    overflowY: "auto",
    position: "absolute",
    top: 0,
    width: "100vw",
  },
  logo: {
    height: "100px",
    marginBottom: "24px",
    marginTop: "24px",
  },
  logoWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    border: "1px solid lightgray",
    display: "flex",
    flexDirection: "column",
    width: "400px",
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(6),
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
}));
SignInUserForm.propTypes = { view: PropTypes.string };
export default React.memo(SignInUserForm);
