import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { LinearProgress } from "@material-ui/core";
import { Auth } from "../config.js";
import { acSetCurrentUser } from "../state/actions/auth";
import { RESET_AUTH_STORE, RESET_FIRESTORE_STORE } from "../state/types";

const PrivateRoute = (props) => {
  const [auth, setAuth] = useState({ checked: false, user: null });
  const dispatch = useDispatch();

  const authListener = () => {
    const unsubscribe = Auth.onIdTokenChanged(async (user) => {
      if (user) {
        const { claims } = await Auth.currentUser.getIdTokenResult();
        dispatch(acSetCurrentUser(claims));
        setAuth({ checked: true, user: claims });
      } else {
        dispatch({ type: RESET_AUTH_STORE });
        dispatch({ type: RESET_FIRESTORE_STORE });
        setAuth({ checked: true, user: null });
      }
    });
    return () => {
      unsubscribe();
      dispatch({ type: RESET_AUTH_STORE });
      dispatch({ type: RESET_FIRESTORE_STORE });
      setAuth({ checked: true, user: null });
    };
  };
  useEffect(authListener, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (auth.checked && auth.user)
    return <Route path={props.path} component={props.component} />;
  else if (auth.checked && !auth.user) return <Redirect to={"/signin"} />;
  else return <LinearProgress color="secondary" />;
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  path: PropTypes.string,
};
export default PrivateRoute;
