import { useEffect } from "react";
import { getQueuedBatteryOrders } from "../actions/firestore.js";
import { useDispatch, useSelector } from "react-redux";

const useQueuedBatteryOrders = () => {
  const dispatch = useDispatch();
  const queuedBatteryOrders = useSelector(
    (state) => state.firestore.queuedBatteryOrders
  );
  const queuedBatteryOrdersListener = useSelector(
    (state) => state.firestore.queuedBatteryOrdersListener
  );

  useEffect(() => {
    if (!queuedBatteryOrdersListener) dispatch(getQueuedBatteryOrders);
  }, [dispatch, queuedBatteryOrdersListener]);

  return queuedBatteryOrders;
};

export default useQueuedBatteryOrders;
