import React, { Suspense, useMemo } from "react";
import { useSelector } from "react-redux";
import { Switch, Redirect, Route } from "react-router-dom";
import { LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDrawer } from "hooks";
import { routes } from "routes";

const MainView = () => {
  const { currentUser } = useSelector((state) => state.auth);
  const { drawerMargin } = useDrawer();
  const classes = useStyles();
  const userRedirect = useMemo(() => {
    if (currentUser?.group === "HMA Techline")
      return "/portal/battery-inventory";
    else return "/portal/battery-pickups";
  }, [currentUser]);

  if (!drawerMargin) return <LinearProgress color="secondary" />; // Do not load page until first drawerMargin is known; Prevents improper animation effect
  return (
    <main className={classes.mainContent}>
      <div style={drawerMargin}>
        <Suspense fallback={<LinearProgress color="secondary" />}>
          <div className={classes.mainPadding}>
            <Switch>
              {routes.map(({ component, exact, path, strict }) => {
                return (
                  <Route
                    key={path}
                    exact={exact}
                    path={path}
                    strict={strict}
                    component={component}
                  />
                );
              })}
              <Redirect exact to={userRedirect} />
            </Switch>
          </div>
        </Suspense>
      </div>
    </main>
  );
};

const useStyles = makeStyles((theme) => ({
  mainContent: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    minWidth: 0, // So the Typography noWrap works
    "@media print": { backgroundColor: "#FFFFFF" },
  },
  mainPadding: {
    [theme.breakpoints.down("xl")]: {
      padding: "24px 24px 24px 32px",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "24px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "8px 16px 16px 16px",
    },
  },
}));
export default MainView;
