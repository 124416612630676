import React, { useCallback } from "react";
import PropTypes from "prop-types";
// import {useHistory} from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { PowerSettingsNew } from "@material-ui/icons";
import { Auth } from "config";

const LogoutItem = ({ classes }) => {
  // const {push: historyPush} = useHistory();
  const handleLogout = useCallback(() => {
    Auth.signOut();
    const redirect =
      !process.env.NODE_ENV || process.env.NODE_ENV === "development"
        ? "http://localhost:3000/signin"
        : "https://hma.xevstrategies.com/signin";
    setTimeout(() => (window.location = redirect), 250);
  }, []);

  return (
    <ListItem
      button
      classes={{ root: classes.listItem }}
      onClick={handleLogout}
    >
      <ListItemIcon classes={{ root: classes.listItemIcon }}>
        <PowerSettingsNew />
      </ListItemIcon>
      <ListItemText
        primary={"Log Out"}
        classes={{ primary: classes.listItemTextPrimary }}
      />
    </ListItem>
  );
};

LogoutItem.propTypes = { classes: PropTypes.object.isRequired };
export default React.memo(LogoutItem);
