import React, { useEffect, useState } from "react"; // eslint-disable-line no-unused-vars
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { acSetModal } from "state/ui/actions";

const useModal = () => {
  const transitionDuration = 350;
  const dispatch = useDispatch();

  // Logic to determin whether modal should display fullScreen on small devices
  const [fullScreen, setFullscreen] = useState(false);
  const theme = useTheme();
  const queryResult = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => setFullscreen(queryResult), [queryResult]);

  // Logic to control mmanagement of modal redux state
  const setModal = (newSettings) => dispatch(acSetModal(newSettings));
  const clearModal = () => {
    dispatch(acSetModal({ open: false }));
    setTimeout(
      () => dispatch(acSetModal({ item: null, stage: "initial", target: "" })),
      transitionDuration
    );
  };

  const defaultDialog = {
    fullScreen,
    fullWidth: true,
    maxWidth: "sm",
    scroll: "body",
    transitionDuration,
  };

  return { defaultDialog, setModal, clearModal };
};

export default useModal;
