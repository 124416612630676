import {
  SET_PENDING,
  SET_TOUCHED,
  SET_DEALERSHIPS,
  FORGET_DEALERSHIPS,
} from "./types";
import { Auth, Firestore } from "config";
const snapOptions = { includeMetadataChanges: true };
const findDocIndex = (array, id) =>
  array.findIndex((item) => item.DocId === id);
const collectionRef = Firestore.collection("dealerships").where(
  "Active",
  "==",
  true
);

const dealershipList = [];
const dealershipRef = {};
let dealershipUnsubscribe = null;
export const acGetDealerships = (currentUser) => (dispatch, getState) => {
  // Used to prevent additional executions; This AC should only run when touched property is false;
  dispatch({ type: SET_TOUCHED, payload: true });

  // Use Auth listener to prevent unauthorized access from non-users; Also clears data when user logs off;
  Auth.onAuthStateChanged((user) => {
    if (user) {
      let queryRef = null;

      // Return entire affiliates collection when user has a management level role...
      if (["Admin", "KBI", "HMA", "HMA Techline"].includes(currentUser.group))
        queryRef = collectionRef;
      // else when user is an Dealership return docs specifically related to that Dealership user...
      else if (currentUser.group === "Dealership")
        queryRef = collectionRef.where(
          "DealerCode",
          "==",
          currentUser.corporateCode
        );
      // else when user is a Dealership return early and set pending to false since query of affiliate collection not needed
      else if (currentUser.group === "Affiliate") {
        dispatch({ type: SET_PENDING, payload: false });
        return;
      }

      const handleSnap = (snap) => {
        const docChangeSize = snap.docChanges().length;
        const isPending = getState().dealerships.pending;

        // If collection is empty and redux state is pending, then set pending to false to show first snap performed
        if (docChangeSize === 0 && isPending)
          dispatch({ type: SET_PENDING, payload: false });
        // If 1 or more doc change in snap, then loop through changes and dispatch to redux state
        else if (docChangeSize >= 1) {
          for (let i = 0; i < docChangeSize; i++) {
            const { type, doc } = snap.docChanges()[i];
            const docData = {
              ...doc.data(),
              DocId: doc.id,
              AffiliateSiteId: doc.id,
            };
            if (type === "added") dealershipList.push(docData);
            if (type === "modified")
              dealershipList.splice(
                findDocIndex(dealershipList, doc.id),
                1,
                docData
              );
            if (type === "removed")
              dealershipList.splice(findDocIndex(dealershipList, doc.id), 1);
            dealershipRef[doc.id] = docData;
          }
          const affiliateSites = {
            all: [...dealershipList],
            ref: dealershipRef,
          };
          dispatch({ type: SET_DEALERSHIPS, payload: affiliateSites });
          dispatch({ type: SET_PENDING, payload: false });
        }
      };
      const handleError = (error) =>
        console.log("acGetDealerships Error: ", error.message, { error });
      dealershipUnsubscribe = queryRef.onSnapshot(
        snapOptions,
        handleSnap,
        handleError
      );
    } else {
      dispatch({ type: FORGET_DEALERSHIPS });
      if (dealershipUnsubscribe) dealershipUnsubscribe();
    }
  });
};
