import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { acGetAffiliateSites } from "state/affiliate-sites/actions";

const useAffiliateSites = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.auth);
  const { all, active, inactive, ref, pending, touched } = useSelector(
    (state) => state.affiliateSites
  );

  useEffect(() => {
    if (!touched && currentUser) dispatch(acGetAffiliateSites(currentUser));
  }, [currentUser, dispatch, touched]);

  return { pending, touched, all, active, inactive, ref };
};

export default useAffiliateSites;
