import { useEffect } from "react";
import { getReceivingSiteCollection } from "../actions/firestore.js";
import { useDispatch, useSelector } from "react-redux";

const useReceivingSites = () => {
  const dispatch = useDispatch();
  const receivingSites = useSelector((state) => state.firestore.receivingSites);
  const receivingSiteListener = useSelector(
    (state) => state.firestore.receivingSiteListener
  );

  useEffect(() => {
    if (!receivingSiteListener) dispatch(getReceivingSiteCollection);
  }, [dispatch, receivingSiteListener]);

  return receivingSites;
};

export default useReceivingSites;
