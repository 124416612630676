import { useEffect } from "react";
import { getBatteryTypeCollection } from "../actions/firestore.js";
import { useDispatch, useSelector } from "react-redux";

const useBatteryTypes = () => {
  const dispatch = useDispatch();
  const batteryTypes = useSelector((state) => state.firestore.batteryTypes);
  const batteryTypeListener = useSelector(
    (state) => state.firestore.batteryTypeListener
  );

  useEffect(() => {
    if (!batteryTypeListener) dispatch(getBatteryTypeCollection);
  }, [dispatch, batteryTypeListener]);

  return batteryTypes;
};

export default useBatteryTypes;
