import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import { useSmallDeviceCheck } from "hooks";

const useDrawer = () => {
  const [drawerMargin, setDrawerMargin] = useState(null);
  const theme = useTheme();
  const drawerOpen = useSelector((state) => state.ui.drawerOpen);
  const { smallDevice } = useSmallDeviceCheck();
  const isPrintView = useMediaQuery({ query: "print" });

  useEffect(() => {
    const duration = theme.transitions.duration.enteringScreen;
    const defaultStyle = { transition: `all ${duration}ms ease-in-out` };
    const printStyle = {
      ...defaultStyle,
      margin: theme.spacing(0),
    };
    const standardStyle = {
      ...defaultStyle,
      marginTop: theme.spacing(8),
    };
    const xlStyle = {
      ...defaultStyle,
      marginTop: theme.spacing(8),
      marginLeft: theme.spacing(0) + (drawerOpen ? 300 : 0),
      marginRight: theme.spacing(0),
    };

    if (isPrintView) setDrawerMargin(printStyle);
    else if (smallDevice) setDrawerMargin(standardStyle);
    else if (!smallDevice) setDrawerMargin(xlStyle);
  }, [drawerOpen, isPrintView, smallDevice, theme]);

  return { drawerMargin, drawerOpen };
};

export default useDrawer;
