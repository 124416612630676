import React from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

const ErrorRedirect = ({ error, path }) => {
  console.log("Error: ", error);
  alert(`Error: ${error}`);
  return <Redirect to={path} />;
};

ErrorRedirect.propTypes = {
  error: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};
export default React.memo(ErrorRedirect);
