import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { acGetRepairParts } from "state/repair-parts/actions";

const useRepairParts = () => {
  const dispatch = useDispatch();
  const { all, active, inactive, ref, pending, touched } = useSelector(
    (state) => state.repairParts
  );

  useEffect(() => {
    if (!touched) dispatch(acGetRepairParts);
  }, [dispatch, touched]);

  return { pending, touched, all, active, inactive, ref };
};

export default useRepairParts;
