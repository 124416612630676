import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { StyledPaper } from "components";
import { ButtonPress } from "icons";

const Placeholder = () => {
  const classes = useStyles();

  return (
    <StyledPaper>
      <div style={{ position: "relative", textAlign: "center" }}>
        <Typography variant="h4" className={classes.placeholderText}>
          Choose Option Above to Continue
        </Typography>
        <ButtonPress className={classes.placeholderIcon} />
      </div>
    </StyledPaper>
  );
};

const useStyles = makeStyles((theme) => ({
  placeholderIcon: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "10rem",
    },
    color: theme.palette.primary.light,
    fontSize: "15rem",
    opacity: 0.3,
  },
  placeholderText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
    },
    alignItems: "center",
    color: theme.palette.secondary.dark,
    display: "flex",
    height: "100%",
    justifyContent: "center",
    position: "absolute",
    width: "100%",
  },
}));
export default React.memo(Placeholder);
