import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { IconButton, Snackbar as Snack } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useSnackbar } from "hooks";

const Snackbar = () => {
  const snackbar = useSelector((state) => state.ui.snackbar);
  const { closeSnackbar } = useSnackbar();
  const handleClose = useCallback(
    (event, reason) => {
      if (reason === "clickaway") return;
      closeSnackbar();
    },
    [closeSnackbar]
  );

  return (
    <Snack
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      autoHideDuration={6000}
      message={snackbar?.message || ""}
      open={Boolean(snackbar?.open)}
      onClose={handleClose}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      }
    />
  );
};

export default Snackbar;
