import { SET_COLUMN_STATE, SET_FILTER_STATE } from "./types";

const INITIAL_STATE = {};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_COLUMN_STATE:
      return {
        ...state,
        [payload.tableId]: {
          ...state?.[payload.tableId],
          columnState: payload.newColumnState,
        },
      };
    case SET_FILTER_STATE:
      return {
        ...state,
        [payload.tableId]: {
          ...state?.[payload.tableId],
          filterState: payload.newFilterState,
        },
      };
    default:
      return state;
  }
};
