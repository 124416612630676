import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { acGetBatteryTypes } from "state/battery-types/actions";

const useBatteryTypes = () => {
  const dispatch = useDispatch();
  const { all, active, inactive, ref, pending, touched } = useSelector(
    (state) => state.batteryTypes
  );

  useEffect(() => {
    if (!touched) dispatch(acGetBatteryTypes);
  }, [dispatch, touched]);

  return { pending, touched, all, active, inactive, ref };
};

export default useBatteryTypes;
