import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";

const prodConfig = {
  apiKey: "AIzaSyBj2vw2cRBAHS9q2EcET_4FH2bx2wwdeOM",
  authDomain: "hma-tracking.firebaseapp.com",
  databaseURL: "https://hma-tracking.firebaseio.com",
  projectId: "hma-tracking",
  storageBucket: "hma-tracking.appspot.com",
  messagingSenderId: "733622219104",
};
const devConfig = {
  apiKey: "AIzaSyBhtSOrkZrkA9lb0Z9D6mVsF5AE0UC1dGc",
  authDomain: "hma-portal-dev.firebaseapp.com",
  databaseURL: "https://hma-portal-dev.firebaseio.com",
  projectId: "hma-portal-dev",
  storageBucket: "hma-portal-dev.appspot.com",
  messagingSenderId: "1030241188930",
  appId: "1:1030241188930:web:97196e523988a9803d01db",
  measurementId: "G-B5ZWYMP3R6",
};
firebase.firestore.setLogLevel("error");

const Fire =
  process.env.REACT_APP_ENV === "production"
    ? firebase.initializeApp(prodConfig)
    : firebase.initializeApp(devConfig);

export const Firestore = Fire.firestore();
export const Auth = Fire.auth();
export const Functions = Fire.functions();
export const Storage = Fire.storage();

// Direct export of Firestore's FieldValue utility;
// https://firebase.google.com/docs/reference/node/firebase.firestore.FieldValue
export const FieldValue = firebase.firestore.FieldValue;
