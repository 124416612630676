/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Search,
  Add,
  Security,
  ChevronLeft,
  InsertDriveFile,
  Print,
} from "@material-ui/icons";
import { AsyncLoader, TableWithPaper } from "../components";
import { useBatteryTypes, useReceivingSites } from "../state/hooks/";
import {
  listenAdminSettings,
  listenRepairInventory,
} from "../state/actions/firestore";
import {
  NewPartsModal,
  AdminEditItemModal,
  NewRepairModal,
} from "./BatteryRepair/";
import { Collapse } from "@kbi/component-library";
import { Storage, Firestore } from "../config.js";
import { PrintPart } from "prints/RepairPartLabel";

const BatteryPickup = () => {
  const [modal, setModal] = useState({ type: null, selectedItem: null });
  const [groupedData, setGroupedData] = useState(null);
  const [filterId, setFilterId] = useState(null);
  const [filterTableTitle, setFilterTableTitle] = useState(null);
  const [partsToPrint, setPartsToPrint] = useState(null);
  const { repairInventory, repairs } = useSelector((state) => state.firestore);
  const { currentUser } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(listenAdminSettings({ currentUser, dispatch }), [currentUser]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(listenRepairInventory({ currentUser, dispatch }), [currentUser]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (repairInventory) {
      const inventoryMap = {};
      repairInventory.active.forEach((item) => {
        if (inventoryMap[item["@PNC"].DocId]) {
          inventoryMap[item["@PNC"].DocId].quantity += 1;
        } else {
          inventoryMap[item["@PNC"].DocId] = { ...item, quantity: 1 };
        }
      });
      setGroupedData(Object.values(inventoryMap));
    }
  }, [repairInventory]);

  const core = {
    groupedPartsTable: {
      data: groupedData || [],
      columns: [
        { accessor: "quantity", Header: "Quantity", filterDisable: true },
        { accessor: "@PNC.Value", Header: "PNC" },
        { accessor: "@PartName.Value", Header: "Part Name" },
        { accessor: "@PartNumber.Value", Header: "Part Number" },
        { accessor: "@EolFee.Value", Header: "EOL Fee" },
      ],
      sortInitial: [{ id: "Vehicle", desc: false }],
      paginationSizes: [5, 10, 20],
      paginationInitialSize: 5,
      title: {
        primary: "Parts Inventory",
        secondary: "Email kbrinkley@mobisusa.com to place order for new parts.",
      },

      actionsPerRow: [
        {
          icon: Search,
          tooltip: "View Individual Items",
          onClick: ({ rowData }) => {
            setFilterId(rowData["@PNC"].DocId);
            setFilterTableTitle(rowData["@PNC"].Value);
          },
        },
      ],
      actionsBar: [
        {
          text: "Add Inventory",
          icon: Add,
          onClick: () => setModal({ type: "addInventory", selectedItem: null }),
        },
      ],
      paperProps: {
        className: "HidePrint",
      },
    },
    filteredPartsTable: {
      data:
        repairInventory?.active.filter(
          (item) => item["@PNC"].DocId === filterId
        ) || [],
      columns: [
        { accessor: "RepairInventoryId", Header: "Part Id" },
        { accessor: "DateReceived", Header: "Date Received", type: "datetime" },
        { accessor: "@PNC.Value", Header: "PNC" },
        { accessor: "@PartName.Value", Header: "Part Name" },
        { accessor: "@PartNumber.Value", Header: "Part Number" },
        { accessor: "@EolFee.Value", Header: "EOL Fee" },
        { accessor: "UnitPrice", Header: "Unit Price" },
        { accessor: "ShipmentNumber", Header: "Shipment Number" },
      ],
      sortInitial: [{ id: "RepairInventoryId", desc: false }],
      paginationSizes: [5, 10, 20],
      paginationInitialSize: 5,
      actionsPerRow: [
        {
          icon: InsertDriveFile,
          tooltip: "View shipping document for this item",
          onClick: ({ rowData }) => {
            Firestore.collection("repair-part-orders")
              .doc(rowData.OrderRef)
              .get()
              .then((doc) => {
                return Storage.ref(doc.data().FileName).getDownloadURL();
              })
              .then((url) => {
                window.open(url, "__blank");
              });
          },
        },
        {
          icon: Print,
          tooltip: "Print Item Label",
          onClick: ({ rowData }) =>
            history.push(
              `/print/repair-part-label/${rowData.RepairInventoryId}`
            ),
        },
        {
          icon: Security,
          hide: currentUser.group !== "Admin",
          tooltip: "Admin Edit Item",
          onClick: ({ rowData }) =>
            setModal({ type: "adminEdit", selectedItem: rowData }),
        },
      ],
      title: {
        primary: `Parts Inventory - ${filterTableTitle}`,
        secondary: "Email kbrinkley@mobisusa.com to place order for new parts.",
      },
      actionsBar: [
        {
          text: "Back",
          icon: ChevronLeft,
          onClick: () => setFilterId(null),
        },
      ],
      paperProps: {
        className: "HidePrint",
      },
    },
    batteryRepairTable: {
      data: repairs?.list || [],
      columns: [
        { accessor: "Status", Header: "Status", filterField: "select" },
        { accessor: "RepairId", Header: "KBI Repair No." },
        { accessor: "Battery.VIN", Header: "Origin VIN" },
        { accessor: "BatteryPickup.DealerCode", Header: "Origin Dealer" },
        {
          accessor: "Battery.PartNumber",
          Header: "Part Number",
          filterField: "select",
        },
        { accessor: "Battery.Name", Header: "Name", filterField: "select" },
        {
          accessor: "RepairStarted",
          Header: "Start Time",
          type: "datetime",
          datetimeFormat: "MM/DD/YYYY h:mma",
        },
      ],
      sortInitial: [{ id: "RepairStarted", desc: true }],
      paginationSizes: [5, 10, 20],
      paginationInitialSize: 5,
      actionsPerRow: [
        {
          icon: Search,
          tooltip: "View Repair",
          onClick: ({ rowData }) =>
            history.push(`/portal/battery-repair/${rowData.RepairId}`),
        },
      ],
      title: {
        primary: "Battery Repairs",
      },
      actionsBar: [
        {
          text: "Start Repair",
          icon: Add,
          onClick: () => setModal({ type: "addRepair", selectedItem: null }),
        },
      ],
      paperProps: {
        className: "HidePrint",
      },
    },
    newPartModal: {
      modal,
      close: (parts) => {
        setModal({ type: null, selectedItem: null });
        setPartsToPrint(parts);
      },
    },
    adminEditModal: {
      modal,
      close: () => setModal({ type: null, selectedItem: null }),
    },
    newRepairModal: {
      modal,
      close: () => setModal({ type: null, selectedItem: null }),
    },
  };

  useEffect(() => {
    if (partsToPrint === null) {
      return;
    }
    setTimeout(() => {
      window.print();
    }, 0);
  }, [partsToPrint]);

  if (["Admin", "HMA", "HMA Techline", "KBI"].indexOf(currentUser.group) === -1)
    return <Redirect to={"/signin"} />;
  if (!repairInventory) return <AsyncLoader />;
  return (
    <Fragment>
      <div className="noPrint">
        <TableWithPaper {...core.batteryRepairTable} />
        <Collapse in={!filterId}>
          <TableWithPaper {...core.groupedPartsTable} />
        </Collapse>
        <Collapse in={Boolean(filterId)}>
          <TableWithPaper {...core.filteredPartsTable} />
        </Collapse>
        <NewPartsModal {...core.newPartModal} />
        <NewRepairModal {...core.newRepairModal} />
        <AdminEditItemModal {...core.adminEditModal} />
      </div>
      <div
        className="printOnly"
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 8,
        }}
      >
        {partsToPrint &&
          partsToPrint.map((part) => {
            return <PrintPart part={part} />;
          })}
      </div>
    </Fragment>
  );
};

export default BatteryPickup;
