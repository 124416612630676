import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { PrivateRoute } from "components";

// Route Imports
import App from "./App";
import SignIn from "./views/SignIn/index";
import SignInDealer from "./views/SignIn/SignInDealer";
import Print from "./prints/index";

const PrimaryRoutes = () => {
  return (
    <Switch>
      <Route exact path="/signin" component={SignIn} />
      <Route exact path="/signin/:view" component={SignIn} />
      <Route exact path="/dealer_signin/:jwToken" component={SignInDealer} />
      <Route exact path="/dealer_signin" component={SignInDealer} />
      <PrivateRoute path="/portal" component={App} />
      <PrivateRoute path="/print" component={Print} />
      <Redirect exact to="/portal" />
    </Switch>
  );
};

export default React.memo(PrimaryRoutes);
