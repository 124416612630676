import { SET_CURRENT_USER, RESET_AUTH_STORE } from "../types";

const INITIAL_STATE = {
  currentUser: {
    displayName: null,
    email: null,
    group: null,
    uid: null,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CURRENT_USER:
      return { ...state, currentUser: payload };
    case RESET_AUTH_STORE:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
