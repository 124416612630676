import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/features/global-this";
import React, { Fragment } from "react";
import ReactDOM from "react-dom";

// Import Redux Elements
import { Provider } from "react-redux";
import { store } from "./state/store";

// Import Router Elements
import { BrowserRouter } from "react-router-dom";
import PrimaryRoutes from "./PrimaryRoutes";

// Import Design Elements
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import "./index.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css"; // Apply to ag-grid by wrapping in <div className='ag-theme-alpine'>...</div>
import "ag-grid-community/dist/styles/ag-theme-balham.css"; // Apply to ag-grid by wrapping in <div className='ag-theme-balham'>...</div>
const theme = createTheme({
  palette: {
    primary: {
      main: "#6D6E71",
      light: "#9b9c9f",
      dark: "#424346",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#ed1c24",
      light: "#ff5f4e",
      dark: "#b20000",
      contrastText: "#000000",
    },
    background: {
      paper: "#fff",
      default: "#eeeeee",
    },
  },
  shape: {
    borderRadius: 4,
  },
});

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <Fragment>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <PrimaryRoutes />
        </ThemeProvider>
      </Fragment>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
